import appConfig from '../../appConfig.js'
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import ContentContainer from '../Layout/ContentContainer/ContentContainer.js';
import { Button, Form, Grid } from 'semantic-ui-react';

class Error404 extends Component {

  componentWillMount = () => {
    document.title = `${appConfig.app.siteTitle} - 404 Not Found`;
  }

  render() {
    const style = {textAlign: 'center'};
    return (
      <ContentContainer>
        <div className="error-404">
        <Grid container>
        <Grid.Row centered columns={1}>
          <Grid.Column>
          <h2 className="section-title" style={style}>Oops! This is awkward.</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={2}>
          <Grid.Column>
            <p style={style} >That page does not exist. Please double-check your links and try again.</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={2}>
          <Grid.Column>
              <Form style={style}>
                <Button basic color='red' as={Link} to="/">Return home</Button>
              </Form>
          </Grid.Column>
        </Grid.Row>
        </Grid>
        </div>
      </ContentContainer>
    );
  }
}

export default Error404;
