import React, { Component } from "react";
import ContentContainer from "../../Layout/ContentContainer/ContentContainer.js";
import { Button, Form, Grid, Input, Message } from "semantic-ui-react";
import { db, auth, functions } from "../../../firebase/index.js";
import appConfig from "../../../appConfig.js";
import { WOW } from "wowjs/dist/wow.js";
import * as moment from "moment-timezone";

const INITIAL_STATE = {
  firstname: "",
  lastname: "",
  phonenumber: "",
  displayname: "",
  zipcode: "",
  error: null,
  notice: null,
  fieldErrors: {},
  formErrors: [],
  isLoading: true,
  redirectAfterUpdate: false
};

var unsubscribeFromAuthChanges = null;

class AccountProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    this.fieldprofilefirstname = React.createRef();
    this.fieldprofilelastname = React.createRef();
    this.fieldprofiledisplayname = React.createRef();
    this.fieldprofilezipcode = React.createRef();
    this.fieldprofilephonenumber = React.createRef();
  }

  componentWillMount = () => {
    document.title = `${appConfig.app.siteTitle} - Update Profile`;
  };

  componentWillUnmount = () => {
    if (unsubscribeFromAuthChanges !== null) {
      unsubscribeFromAuthChanges();
    }
  };

  componentDidMount = () => {
    new WOW({ live: false }).init();
    unsubscribeFromAuthChanges = auth.onAuthStateChanged(authUser => {
      if (authUser) {
        var docRef = db.collection("userProfiles").doc(auth.currentUser.uid);
        docRef
          .get()
          .then(doc => {
            //this prevents memory leaks
            /* if(isMounted === false) {
              return;
            } */
            if (doc.exists) {
              var docData = doc.data();
              if (docData.updatedAt) {
                this.setState({
                  isLoading: false,
                  firstname: docData.firstName || "",
                  lastname: docData.lastName || "",
                  displayname: docData.displayName || "",
                  phonenumber: docData.phoneNumber || "",
                  zipcode: docData.zipCode || "",
                  redirectAfterUpdate: false
                });
              } else {
                this.setState({
                  isLoading: false,
                  firstname: docData.firstName || "",
                  lastname: docData.lastName || "",
                  displayname: docData.displayName || "",
                  phonenumber: docData.phoneNumber || "",
                  zipcode: docData.zipCode || "",
                  redirectAfterUpdate: false,
                  error: "Your profile must be completed before you can make purchases and fully utilize this app."
                });
              }

            } else {
              this.setState({
                isLoading: false,
                redirectAfterUpdate: true,
                error: "Your profile must be completed before you can make purchases and fully utilize this app."
              });
            }
          })
          .catch(error => {
            this.setState({ isLoading: false, error: `Error: ${error.message}` });
          });
      }
    });
  };

  errorList = () => {
    const style = { margin: "0.5em 0" };
    if (this.state.formErrors.length) {
      const errorLIs = this.state.formErrors.map((errorItem, index) => {
        return <li key={index}>{errorItem}</li>;
      });
      return <ul style={style}>{errorLIs}</ul>;
    }
  };

  errorSection = () => {
    if (this.state.formErrors.length) {
      return (
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Message error size={"tiny"}>
            <Message.Header>Errors</Message.Header>
            <Message.Content>{this.errorList()}</Message.Content>
          </Message>
        </Grid.Column>
      );
    } else {
      return null;
    }
  };

  validateForm = () => {
    const { history } = this.props;
    const firstname = this.fieldprofilefirstname.current.inputRef.value.trim();
    const lastname = this.fieldprofilelastname.current.inputRef.value.trim();
    const displayname = this.fieldprofiledisplayname.current.inputRef.value.trim();
    const zipcode = this.fieldprofilezipcode.current.value
      .trim()
      .replace(/\D+/g, "");
    const phonenumber = this.fieldprofilephonenumber.current.inputRef.value
      .trim()
      .replace(/\D+/g, "");

    this.setState(prevState => ({
      formErrors: [],
      fieldErrors: {}
    }));

    let foundErrors = false;

    if (firstname === "" || firstname.length < 2) {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter a first name"],
        fieldErrors: { ...prevState.fieldErrors, firstname: true }
      }));
      foundErrors = true;
    }

    if (lastname === "" || lastname.length < 2) {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter a last name"],
        fieldErrors: { ...prevState.fieldErrors, lastname: true }
      }));
      foundErrors = true;
    }

    if (displayname === "" || displayname.length < 2) {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter a display name"],
        fieldErrors: { ...prevState.fieldErrors, displayname: true }
      }));
      foundErrors = true;
    }

    if (zipcode === "" || zipcode.length !== 5) {
      this.setState(prevState => ({
        formErrors: [
          ...prevState.formErrors,
          "You must enter a five digit zip code"
        ],
        fieldErrors: { ...prevState.fieldErrors, zipcode: true }
      }));
      foundErrors = true;
    }

    if (phonenumber !== "" && phonenumber.length > 11) {
      this.setState(prevState => ({
        formErrors: [
          ...prevState.formErrors,
          "If you supply a phone number, it must be ten (10) digits with no dashes"
        ],
        fieldErrors: { ...prevState.fieldErrors, phonenumber: true }
      }));
      foundErrors = true;
    }

    if (foundErrors === true) {
      return false;
    }

    this.setState({ isLoading: true, error: null, notice: null });

    const currentMoment = moment().tz("America/New_York");
    const currentUnix = currentMoment.unix();

    const profileObject = {
      firstName: firstname,
      lastName: lastname,
      displayName: displayname,
      phoneNumber: phonenumber,
      zipCode: zipcode,
      updatedAt: currentUnix
    };

    

    
    var updateProfile = functions.httpsCallable("updateProfile");
    updateProfile(profileObject)
      .then(response => {
        //console.log(response);
        if (response.data) {
          if (this.state.redirectAfterUpdate === true) {
            history.push("/dashboard");
            return;
          } else {
            this.setState({
              isLoading: false,
              notice: "User profile updated successfully!"
            });
            this.fieldprofilefirstname.current.inputRef.value = firstname;
            this.fieldprofilelastname.current.inputRef.value = lastname;
            this.fieldprofiledisplayname.current.inputRef.value = displayname;
            this.fieldprofilezipcode.current.value = zipcode;
            this.fieldprofilephonenumber.current.inputRef.value = phonenumber;
          }
        } else {
          this.setState({ isLoading: false, error: "There was an issue updating your profile." });
        }
      })
      .catch(error => {

        this.setState({ isLoading: false, error: `Error: ${error.message}` });
      });
      
  };

  render() {
    /* const style = {textAlign: 'center'}; */
    const style = { textAlign: "center" };
    const errorStyle = {
      fontWeight: "bold",
      color: "#a51a28",
      textAlign: "center"
    };
    const noticeStyle = {
      fontWeight: "bold",
      color: "#28721c",
      textAlign: "center"
    };
    return (
      <ContentContainer>
        <div className="AccountProfile">
          <Grid container>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={10} computer={10}>
                <h2
                  className="section-title wow flipInX"
                  style={style}
                  data-wow-duration="0.35s"
                  data-wow-delay="0s"
                  data-wow-offset="0"
                >
                  Update Profile
                </h2>
                {this.state.error && (
                  <p style={errorStyle}>{this.state.error}</p>
                )}
                {this.state.notice && (
                  <p style={noticeStyle}>{this.state.notice}</p>
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={16} computer={12}>
                <Form
                  autoComplete="off"
                  loading={this.state.isLoading}
                  error={this.state.formErrors.length ? true : false}
                  size={"large"}
                  className="wow fadeIn"
                  data-wow-duration="0.35s"
                  data-wow-delay="0s"
                  data-wow-offset="0"
                  data-wow-iteration="1"
                >
                  <Grid columns={1}>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <Form.Field
                        required
                        error={this.state.fieldErrors.firstname ? true : false}
                      >
                        <label>First Name</label>
                        <Input
                          placeholder="First Name"
                          type="text"
                          name="firstname"
                          disabled={this.state.isLoading}
                          defaultValue={this.state.firstname}
                          ref={this.fieldprofilefirstname}
                          maxLength="20"
                        />
                        <small>
                          Required. Used for e-mail communications, support,
                          etc.
                        </small>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <Form.Field
                        required
                        error={this.state.fieldErrors.lastname ? true : false}
                      >
                        <label>Last Name</label>
                        <Input
                          placeholder="Last Name"
                          type="text"
                          name="lastname"
                          disabled={this.state.isLoading}
                          defaultValue={this.state.lastname}
                          ref={this.fieldprofilelastname}
                          maxLength="20"
                        />
                        <small>
                          Required. Used for e-mail communications, support,
                          etc.
                        </small>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <Form.Field
                        required
                        error={
                          this.state.fieldErrors.displayname ? true : false
                        }
                      >
                        <label>Display Name</label>
                        <Input
                          placeholder="Display Name"
                          type="text"
                          name="displayname"
                          disabled={this.state.isLoading}
                          defaultValue={this.state.displayname}
                          ref={this.fieldprofiledisplayname}
                          maxLength="20"
                        />
                        <small>
                          Required. Name displayed to others on the scoreboard.
                        </small>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <Form.Field
                        required
                        error={this.state.fieldErrors.zipcode ? true : false}
                      >
                        <label>Zip Code</label>
                        <input
                          placeholder="12345"
                          type="tel"
                          name="zipcode"
                          disabled={this.state.isLoading}
                          defaultValue={this.state.zipcode}
                          ref={this.fieldprofilezipcode}
                          maxLength="5"
                        />
                        <small>Required. Used for usage statistics.</small>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column
                      mobile={16}
                      tablet={8}
                      computer={8}
                      floated={"right"}
                    >
                      <Form.Field
                        error={
                          this.state.fieldErrors.phonenumber ? true : false
                        }
                      >
                        <label>Phone Number</label>
                        <Input
                          placeholder="2223334444"
                          type="text"
                          name="phonenumber"
                          disabled={this.state.isLoading}
                          defaultValue={this.state.phonenumber}
                          ref={this.fieldprofilephonenumber}
                          maxLength="15"
                        />
                        <small>
                          Optional.
                        </small>
                      </Form.Field>
                    </Grid.Column>
                    {this.errorSection()}
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <Button
                        primary
                        type="submit"
                        size={"large"}
                        floated={"right"}
                        onClick={this.validateForm}
                        disabled={this.state.isLoading}
                      >
                        Update
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </ContentContainer>
    );
  }
}

export default AccountProfile;
