import React  from 'react';
import { Grid, Loader } from 'semantic-ui-react';


const SimpleNotification = (props) => {
  const style = { textAlign: "center", paddingBottom: "15px" };
  const errorStyle = { textAlign: 'left' };

  const showError = props.error ? (
    <Grid.Row centered>
      <Grid.Column mobile={16} tablet={10} computer={8}>
        <div style={errorStyle} className="ui negative message">
          <div className="header">{props.error.code}</div>
          <p>{props.error.message}</p>
        </div>
      </Grid.Column>
    </Grid.Row>
  ) : (null);

  const loaderOrContent = props.loading === true ? (
    <Loader active inline='centered' size="large"></Loader>
  ) : (
    props.content
  );

  return (
    <Grid container>
      <Grid.Row centered>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <h2 className="section-title" style={style}>{props.heading}</h2>
        </Grid.Column>
      </Grid.Row>
      {showError}
      <Grid.Row centered>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          {loaderOrContent}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default SimpleNotification;
