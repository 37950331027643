
import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import * as firebase from "../../firebase/index.js";

import Error404 from "./Error404.js";
import OffCanvasMenu from "../Layout/Navbar/OffCanvasMenu.js";
import Header from "../Layout/Header/Header.js";

import Homepage from "../Homepage/Homepage.js";
import AccountRegistration from "../Accounts/Registration/AccountRegistration.js";
import AccountSignin from "../Accounts/SignIn/AccountSignin.js";
import AccountAction from "../Accounts/Action/AccountAction.js";
import ForgotPassword from "../Accounts/ForgotPassword/ForgotPassword.js";
import ResetPassword from "../Accounts/ResetPassword/ResetPassword.js";
import Dashboard from "../Dashboard/Dashboard.js";
import ChangePassword from "../Accounts/ChangePassword/ChangePassword.js";
import AccountProfile from "../Accounts/AccountProfile/AccountProfile.js";
import SessionPage from "../Session/SessionPage.js";
import SessionPayment from "../Session/SessionPayment.js";
import Testing from "../Testing/Testing.js";

const ProtectedRoute = ({ component: Component, appLoading, ...rest }) => (
  <Route
    {...rest}
    render={renderProps =>
      firebase.isAuthenticated() || appLoading ? (
        <Component {...renderProps} />
      ) : (
        <Redirect
          to={{
            pathname: "/account/sign-in",
            state: { from: renderProps.location }
          }}
        />
      )
    }
  />
);

class Router extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authorizedUser: null,
      appLoading: true,
      offCanvasVisible: false
    };
  }

  toggleOffCanvasMenu = () => {
    this.setState(prevState => ({
      offCanvasVisible: !prevState.offCanvasVisible
    }));
  };

  currentOffCanvasState = stateObj => {
    this.setState({ offCanvasVisible: stateObj.isOpen });
  };

  componentDidMount() {
    firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState(() => ({ authorizedUser: authUser, appLoading: false }))
        : this.setState(() => ({ authorizedUser: null, appLoading: false }));
    });
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <OffCanvasMenu
            isOpen={this.state.offCanvasVisible}
            onStateChange={this.currentOffCanvasState}
            toggleOffCanvas={this.toggleOffCanvasMenu}
            authorizedUser={this.state.authorizedUser}
            appLoading={this.state.appLoading}
          />
          <Header
            authorizedUser={this.state.authorizedUser}
            appLoading={this.state.appLoading}
            toggleOffCanvas={this.toggleOffCanvasMenu}
          />
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route path="/error404" component={Error404} docTitle="Error" />
            <Route path="/account/register" component={AccountRegistration} />
            <Route path="/account/sign-in" component={AccountSignin} />
            <Route path="/account/action" component={AccountAction} />
            <Route path="/account/forgotpassword" component={ForgotPassword} />
            <Route path="/account/resetpassword" component={ResetPassword} />
            <Route path="/session/:sessionID/payment" render={(props) => <SessionPayment {...props} authorizedUser={this.state.authorizedUser} appLoading={this.state.appLoading} />}/>
            <Route path="/session/:sessionID/teams/:epocDate?" render={(props) => <SessionPage {...props} subMenuName="teams" authorizedUser={this.state.authorizedUser} appLoading={this.state.appLoading} />}/>
            <Route path="/session/:sessionID/habits/:epocDate?" render={(props) => <SessionPage {...props} subMenuName="habits" authorizedUser={this.state.authorizedUser} appLoading={this.state.appLoading} />}/>
            <Route path="/session/:sessionID/stats" render={(props) => <SessionPage {...props} subMenuName="stats" authorizedUser={this.state.authorizedUser} appLoading={this.state.appLoading} />}/>
            <Route path="/session/:sessionID/rankings" render={(props) => <SessionPage {...props} subMenuName="rankings" authorizedUser={this.state.authorizedUser} appLoading={this.state.appLoading} />}/>
            <Route path="/session/:sessionID" render={(props) => <SessionPage {...props} subMenuName="overview" authorizedUser={this.state.authorizedUser} appLoading={this.state.appLoading} />}/>
            
            <ProtectedRoute
              path="/dashboard"
              appLoading={this.state.appLoading}
              component={Dashboard}
            />
            <ProtectedRoute
              path="/account/profile"
              appLoading={this.state.appLoading}
              component={AccountProfile}
            />
            <ProtectedRoute
              path="/account/changepassword"
              appLoading={this.state.appLoading}
              component={ChangePassword}
            />
            <ProtectedRoute
              path="/admintest"
              appLoading={this.state.appLoading}
              component={Testing}
            />
            <Route component={Error404} docTitle="Error" />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default Router;
