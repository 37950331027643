/* eslint-disable no-undef */
import React, { Component } from "react";
import appConfig from "../../appConfig.js";
import * as moment from "moment-timezone";
import ContentContainer from "../Layout/ContentContainer/ContentContainer.js";
import { Form, Grid, Button, Input, Message, Image } from "semantic-ui-react";
import { WOW } from "wowjs/dist/wow.js";
import { db, functions, auth } from "../../firebase/index.js";
import "./SessionPayment.css";
import { Link } from "react-router-dom";

const INITIAL_STATE = {
  error: null,
  isLoading: true,
  billingCardType: "",
  billingCardholderName: "",
  billingCardNumber: "",
  billingExpMonth: "",
  billingExpYear: "",
  billingCardCVC: "",
  billingStripeToken: "",
  basePrice: null,
  eventStartDate: null,
  sessionDuration: null,
  formErrors: [],
  fieldErrors: {},
  purchaseCompleted: false,
  purchaseReceipt: {},
  promoType: null,
  promoDiscount: 0,
  promoCode: null
};

const cardTypeOptions = [
  { value: "Visa/MasterCard/Discover", text: "Visa/MasterCard/Discover" },
  { value: "American Express", text: "American Express" }
];

const expirationMonthOptions = [
  { value: "01", text: "01 - January" },
  { value: "02", text: "02 - February" },
  { value: "03", text: "03 - March" },
  { value: "04", text: "04 - April" },
  { value: "05", text: "05 - May" },
  { value: "06", text: "06 - June" },
  { value: "07", text: "07 - July" },
  { value: "08", text: "08 - August" },
  { value: "09", text: "09 - September" },
  { value: "10", text: "10 - October" },
  { value: "11", text: "11 - November" },
  { value: "12", text: "12 - December" }
];

var unsubscribeFromAuthChanges = null;

const expirationYearOptions = () => {
  let yearOptions = [];
  const currentYear = moment().year();
  for (let x = 0; x < 8; x++) {
    yearOptions.push({ value: currentYear + x, text: `${currentYear + x}` });
  }
  return yearOptions;
};

class SessionPayment extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    this.fieldBillingCardType = React.createRef();
    this.fieldBillingCardholderName = React.createRef();
    this.fieldBillingCardNumber = React.createRef();
    this.fieldBillingExpMonth = React.createRef();
    this.fieldBillingExpYear = React.createRef();
    this.fieldBillingCardCVC = React.createRef();
    this.fieldPromoCode = React.createRef();
  }

  componentWillMount = () => {
    document.title = `${appConfig.app.siteTitle} - Session Payment`;
  };

  componentWillUnmount = () => {
    if (unsubscribeFromAuthChanges !== null) {
      unsubscribeFromAuthChanges();
    }
  };

  getSessionData = () => {
    const { sessionID } = this.props.match.params;
    var sessionsRef = db.collection("rewireSessions").doc(sessionID);
    sessionsRef
      .get()
      .then(doc => {
        if (doc.exists) {
          let docData = doc.data();
          var sessionFee = parseFloat(Math.round(docData.price * 100) / 100).toFixed(2);
          var startDateMoment = moment.unix(docData.startDate);
          var endDateMoment = moment.unix(docData.endDate);
          var niceStartDate = startDateMoment.format("L");
          var niceEndDate = endDateMoment.format("L");
          var sessionDuration = endDateMoment.diff(startDateMoment, "days") + 1;

          document.title = `${appConfig.app.siteTitle} - ${niceStartDate} Session Payment`;

          this.setState({
            isLoading: false,
            basePrice: sessionFee,
            eventStartDate: niceStartDate,
            eventEndDate: niceEndDate,
            sessionID: sessionID,
            sessionNotFound: false,
            sessionDuration: sessionDuration
          });
        } else {
          this.setState({ isLoading: false, sessionNotFound: true });
        }
      })
      .catch(error => {
        this.setState(prevState => ({
          isLoading: false,
          formErrors: [...prevState.formErrors, `${error.code} - ${error.message}`]
        }));
      });
  };

  applyPromoCodeKeypress = e => {
    if (e.key === "Enter") {
      this.checkPromoCode();
    }
  };

  calculatePromoValue = () => {
    let finalAmt = 0.0;
    if (this.state.promoType === "fixed") {
      let originalAmt = parseFloat(this.state.promoDiscount);
      finalAmt = parseFloat(Math.round(originalAmt * 100) / 100).toFixed(2);
    } else if (this.state.promoType === "percentage") {
      let percentageDiscount = parseFloat(this.state.promoDiscount);
      let currentPrice = parseFloat(this.state.basePrice);
      finalAmt = parseFloat(Math.round(currentPrice * percentageDiscount * 100) / 100).toFixed(2);
    }
    return finalAmt;
  };

  calculatePayPrice = () => {
    const basePrice = parseFloat(Math.round(this.state.basePrice * 100) / 100).toFixed(2);
    const promoValue = parseFloat(Math.round(this.calculatePromoValue() * 100) / 100).toFixed(2);

    const finalPrice = parseFloat(Math.round((parseFloat(basePrice) - parseFloat(promoValue)) * 100) / 100).toFixed(2);

    return finalPrice;
  };

  checkPromoCode = () => {
    this.setState(prevState => ({
      formErrors: [],
      fieldErrors: {},
      isLoading: true
    }));
    let promoCode = this.fieldPromoCode.current.inputRef.value.trim().toLowerCase();
    if (promoCode === "") {
      this.setState(prevState => ({
        isLoading: false,
        promoType: null,
        promoDiscount: 0,
        promoCode: ""
      }));
      return;
    }
    let promoField = this.fieldPromoCode.current.inputRef;
    var docRef = db.collection("promotions").doc(promoCode);
    docRef
      .get()
      .then(doc => {
        this.setState({ isLoading: false });
        if (doc.exists) {
          this.setState({
            promoType: doc.data().type,
            promoDiscount: doc.data().amount,
            promoCode: promoCode
          });
          promoField.value = "";
        } else {
          // doc.data() will be undefined in this case
          this.setState(prevState => ({
            formErrors: [...prevState.formErrors, `The promo code '${promoCode}' is invalid`],
            fieldErrors: { ...prevState.fieldErrors, fieldPromoCode: true },
            promoType: null,
            promoDiscount: 0,
            promoCode: ""
          }));
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        //console.error("Error getting document:");
        //console.table(error);
        this.setState(prevState => ({
          formErrors: [...prevState.formErrors, `${error.code} - ${error.message}`],
          promoType: null,
          promoDiscount: 0,
          promoCode: ""
        }));
      });
  };

  errorList = () => {
    const style = { margin: "0.5em 0" };
    if (this.state.formErrors.length) {
      const errorLIs = this.state.formErrors.map((errorItem, index) => {
        return <li key={index}>{errorItem}</li>;
      });
      return <ul style={style}>{errorLIs}</ul>;
    }
  };

  errorSection = () => {
    if (this.state.formErrors.length) {
      return (
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Message error size={"tiny"}>
            <Message.Header>Errors</Message.Header>
            <Message.Content>{this.errorList()}</Message.Content>
          </Message>
        </Grid.Column>
      );
    } else {
      return null;
    }
  };

  componentDidMount = () => {
    new WOW({ live: false }).init();

    unsubscribeFromAuthChanges = auth.onAuthStateChanged(authUser => {
      if (authUser) {
        const { history } = this.props;
        var profileReference = db.collection("userProfiles").doc(auth.currentUser.uid);
        profileReference
          .get()
          .then(doc => {
            if (doc.exists) {
              var docData = doc.data();
              if (!docData.updatedAt) {
                history.push("/account/profile");
              } else {
                this.getSessionData();
              }
            } else {
              history.push("/account/profile");
            }
          })
          .catch(error => {
            this.setState({ isLoading: false, error: error });
          });
      }
    });
  };

  purchaseBreakdown = () => {
    const headingStyle = {
      marginTop: "0px",
      marginBottom: "0px",
      fontWeight: "400"
    };
    if (this.state.basePrice !== null && this.state.eventStartDate != null) {
      return (
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Grid columns={1}>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <h3 style={headingStyle}>
                  <strong>Session Start Date:</strong> {this.state.eventStartDate}
                </h3>
                <h3 style={headingStyle}>
                  <strong>Session End Date:</strong> {this.state.eventEndDate}
                </h3>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <h3 style={headingStyle}>
                  <strong>Session Length:</strong> {this.state.sessionDuration} days
                </h3>
                <h3 style={headingStyle}>
                  <strong>Purchase Price:</strong> ${this.state.basePrice}
                </h3>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      );
    } else {
      return null;
    }
  };

  processForm = () => {
    if (this.calculatePayPrice() === "0.00") {
      this.processFullPromotion();
    } else {
      this.validateBillingDetails();
    }
  };

  processFullPromotion = () => {
    this.setState({ isLoading: true });
    let currentMoment = moment.tz(moment.tz.guess()).unix();
    var enrollInSession = functions.httpsCallable("enrollInPromoSession");
    //var addMailchimpPurchaseTag = functions.httpsCallable("addMailChimpPurchaseTag");
    var updateActiveCampaign = functions.httpsCallable("tagUserInActiveCampaign");
    var getSessionTag = functions.httpsCallable("upsertCurrentActiveCampaignTags");

    const purchaseObj = {
      sessionID: this.state.sessionID,
      enrollDate: currentMoment,
      promoCode: this.state.promoCode
    };

    enrollInSession(purchaseObj)
      .then(enrollResponse => {
        if (enrollResponse.data.enrollmentAdded === true) {
          //if we received the response we are looking for...
          /* this.setState(prevState => ({
                        isLoading: false,
                        purchaseCompleted: true
                      })); */
        } else {
          this.setState(prevState => ({
            isLoading: false,
            formErrors: [
              ...prevState.formErrors,
              "There was an issue during enrollment. Please contact support@routinerewire.com"
            ]
          }));
        }

        this.setState(prevState => ({
          promoReceipt: currentMoment
        }));

        //updateActiveCampaign()

        getSessionTag({ sessionDate: this.state.eventStartDate }).then(response => {
          var tagObj = {}
          if(response.data.foundTagId) {
            tagObj["sessionTagNumber"] = response.data.foundTagId;
          }
          updateActiveCampaign(tagObj).then(response => {
            this.setState(prevState => ({
              isLoading: false,
              purchaseCompleted: true
            }));
          });
        });


      })
      .catch(error => {
        this.setState(prevState => ({
          isLoading: false,
          formErrors: [...prevState.formErrors, `${error.code} - ${error.message}`]
        }));
      });
  };

  validateBillingDetails = () => {
    //get values
    const billingCardholderName = this.fieldBillingCardholderName.current.inputRef.value.trim();
    const billingCardCVC = this.fieldBillingCardCVC.current.inputRef.value.trim().replace(/\D+/g, "");
    const billingCardNumber = this.fieldBillingCardNumber.current.inputRef.value.trim().replace(/\D+/g, "");
    this.fieldBillingCardNumber.current.inputRef.value = billingCardNumber;
    this.fieldBillingCardCVC.current.inputRef.value = billingCardCVC;

    const billingCardType = this.fieldBillingCardType.current.value.trim();
    const billingExpMonth = this.fieldBillingExpMonth.current.value.trim();
    const billingExpYear = this.fieldBillingExpYear.current.value.trim();

    //clear out previous error states
    this.setState(prevState => ({
      formErrors: [],
      fieldErrors: {}
    }));

    let foundErrors = false;

    //card type checks
    if (billingCardType === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must choose a card type"],
        fieldErrors: { ...prevState.fieldErrors, billingCardType: true }
      }));
      foundErrors = true;
    }

    //cardholder name checks
    if (billingCardholderName === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter a cardholder name"],
        fieldErrors: { ...prevState.fieldErrors, billingCardholderName: true }
      }));
      foundErrors = true;
    }

    //cardnumber checks
    if (billingCardNumber === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter a card number"],
        fieldErrors: { ...prevState.fieldErrors, billingCardNumber: true }
      }));
      foundErrors = true;
    }

    //expiration month checks
    if (billingExpMonth === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must select a card expiration month"],
        fieldErrors: { ...prevState.fieldErrors, billingExpMonth: true }
      }));
      foundErrors = true;
    }

    //expiration year checks
    if (billingExpYear === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must select a card expiration year"],
        fieldErrors: { ...prevState.fieldErrors, billingExpYear: true }
      }));
      foundErrors = true;
    }

    //card cvc checks
    if (billingCardCVC === "") {
      this.setState(prevState => ({
        formErrors: [...prevState.formErrors, "You must enter the card verification code (CVC)"],
        fieldErrors: { ...prevState.fieldErrors, billingCardCVC: true }
      }));
      foundErrors = true;
    }

    if (foundErrors === true) {
      return false;
    }

    /*
      ****************************************************************************
      ****************************************************************************
      ****************************************************************************
    */

    this.setState({ isLoading: true });
    //const stripekey = process.env.NODE_ENV === 'production' ? appConfig.stripe.publishableKey : appConfig.stripe.testingKey;
    let chargeMode = null;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      Stripe.setPublishableKey(appConfig.stripe.testingKey);
      chargeMode = "test";
    } else {
      Stripe.setPublishableKey(appConfig.stripe.publishableKey);
      chargeMode = "prod";
      //Stripe.setPublishableKey(appConfig.stripe.testingKey);
      //chargeMode = "test";
    }

    /*
      ****************************************************************************
      ****************************************************************************
      ****************************************************************************
    */

    Stripe.card.createToken(
      {
        name: billingCardholderName,
        number: billingCardNumber,
        cvc: billingCardCVC,
        exp_month: billingExpMonth,
        exp_year: billingExpYear
      },
      (status, response) => {
        if (response.error) {
          //console.error(response.error);
          this.setState({ isLoading: false });
          switch (response.error.code) {
            case "incorrect_number": {
              foundErrors = true;
              this.setState(prevState => ({
                formErrors: [...prevState.formErrors, "Your card number is incorrect"],
                fieldErrors: {
                  ...prevState.fieldErrors,
                  billingCardNumber: true
                }
              }));
              break;
            }
            case "card_declined": {
              foundErrors = true;
              this.setState(prevState => ({
                formErrors: [...prevState.formErrors, "Your card number is invalid"],
                fieldErrors: {
                  ...prevState.fieldErrors,
                  billingCardNumber: true
                }
              }));
              break;
            }
            case "invalid_expiry_month": {
              foundErrors = true;
              this.setState(prevState => ({
                formErrors: [...prevState.formErrors, "Your card expiration month is invalid"],
                fieldErrors: { ...prevState.fieldErrors, billingExpMonth: true }
              }));
              break;
            }
            case "invalid_expiry_year": {
              foundErrors = true;
              this.setState(prevState => ({
                formErrors: [...prevState.formErrors, "Your card expiration year is invalid"],
                fieldErrors: { ...prevState.fieldErrors, billingExpYear: true }
              }));
              break;
            }
            case "invalid_cvc": {
              foundErrors = true;
              this.setState(prevState => ({
                formErrors: [...prevState.formErrors, "Your card verification code (CVC) is invalid"],
                fieldErrors: { ...prevState.fieldErrors, billingCardCVC: true }
              }));
              break;
            }
            default: {
              foundErrors = true;
              this.setState(prevState => ({
                formErrors: [...prevState.formErrors, response.error.message]
              }));
            }
          } //end of switch
        } else {
          //console.log("RESPONSE:" + response.id);
          const billingStripeToken = response.id;

          const purchaseObj = {
            sessionID: this.state.sessionID,
            sessionDate: this.state.eventStartDate,
            stripeToken: billingStripeToken,
            mode: chargeMode
          };

          var makePurchase = functions.httpsCallable("purchaseSession");
          var enrollInSession = functions.httpsCallable("enrollInSession");
          //var addMailchimpPurchaseTag = functions.httpsCallable("addMailChimpPurchaseTag");
          var tagUserInActiveCampaign = functions.httpsCallable("tagUserInActiveCampaign");
          var getSessionTag = functions.httpsCallable("upsertCurrentActiveCampaignTags");


          makePurchase(purchaseObj)
            .then(response => {
              if (response.data.status === "succeeded" && response.data.captured === true) {
                //charge was successful...
                this.setState(prevState => ({
                  purchaseReceipt: response.data
                }));
                enrollInSession({ receiptInfo: response.data })
                  .then(enrollResponse => {
                    if (enrollResponse.data.enrollmentAdded === true) {
                      //if we received the response we are looking for...
                      /* this.setState(prevState => ({
                        isLoading: false,
                        purchaseCompleted: true
                      })); */
                    } else {
                      this.setState(prevState => ({
                        isLoading: false,
                        formErrors: [
                          ...prevState.formErrors,
                          "There was an issue during enrollment. Please contact support@routinerewire.com"
                        ]
                      }));
                    }
                    getSessionTag({ sessionDate: this.state.eventStartDate }).then(response => {
                      var tagObj = {}
                      if(response.data.foundTagId) {
                        tagObj["sessionTagNumber"] = response.data.foundTagId;
                      }
                      tagUserInActiveCampaign(tagObj).then(response => {
                        this.setState(prevState => ({
                          isLoading: false,
                          purchaseCompleted: true
                        }));
                      });
                    });

                  })
                  .catch(error => {
                    this.setState(prevState => ({
                      isLoading: false,
                      formErrors: [...prevState.formErrors, `${error.code} - ${error.message}`]
                    }));
                  });
              } else {
                console.log(response);
                this.setState(prevState => ({
                  isLoading: false,
                  formErrors: [...prevState.formErrors, "There was an issue capturing the payment. Cannot continue."]
                }));
              }
            })
            .catch(error => {
              //error.code, error.message
              this.setState(prevState => ({
                isLoading: false,
                formErrors: [...prevState.formErrors, `${error.code} - ${error.message}`]
              }));
            });

          //this.gotoNextStep();
        }
      }
    );
  };

  purchaseCompleted = () => {
    const style = { textAlign: "center" };

    const purchaseTimeMoment = moment.unix(this.state.purchaseReceipt.created);
    const nicePurchaseTime = purchaseTimeMoment.tz(moment.tz.guess()).format("dddd, MMMM Do YYYY, h:mm:ss a z");
    const purchaseAmount = Math.round(this.state.purchaseReceipt.amount / 100).toFixed(2);
    return (
      <ContentContainer>
        <div className="purchase-completed">
          <Grid container>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={16} computer={10}>
                <div className="greenbox">
                  <h2
                    className="section-title wow flipInX"
                    style={style}
                    data-wow-duration="0.35s"
                    data-wow-delay="0s"
                    data-wow-offset="0"
                  >
                    Purchase Receipt
                  </h2>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Purchase Timestamp:</strong>
                          <br />
                          {nicePurchaseTime}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Transaction ID:</strong>
                          <br />
                          {this.state.purchaseReceipt.id}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Customer ID:</strong>
                          <br />
                          {this.state.purchaseReceipt.customer}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Purchase Amount:</strong>
                          <br />${purchaseAmount}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p style={style}>
                  <br />
                  You should print this page for your records. You will also receive an e-mail receipt from Stripe.
                </p>
                <div style={style}>
                  <Button primary size={"huge"} color="red" as={Link} to={`/dashboard`}>
                    Open My Dashboard
                  </Button>
                  {this.unbounceJSInsert()}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </ContentContainer>
    );
  };

  unbounceJSInsert = () => {
    window._ubaq = window._ubaq || [];
    window._ubaq.push(['trackGoal', 'convert']);

    (function () {
      var ub_script = document.createElement('script');
      ub_script.type = 'text/javascript';
      // eslint-disable-next-line
      ub_script.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'd3pkntwtp2ukl5.cloudfront.net/uba.js';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ub_script, s);
      //alert("INSERTED!");
    })();
  }

  fullPromotionCompleted = () => {
    const style = { textAlign: "center" };

    const purchaseTimeMoment = moment.unix(this.state.promoReceipt);
    const nicePurchaseTime = purchaseTimeMoment.format("dddd, MMMM Do YYYY, h:mm:ss a z");
    return (
      <ContentContainer>
        <div className="purchase-completed">
          <Grid container>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={16} computer={10}>
                <div className="greenbox">
                  <h2
                    className="section-title wow flipInX"
                    style={style}
                    data-wow-duration="0.35s"
                    data-wow-delay="0s"
                    data-wow-offset="0"
                  >
                    Purchase Receipt
                  </h2>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Purchase Timestamp:</strong>
                          <br />
                          {nicePurchaseTime}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Promotion Used:</strong>
                          <br />
                          {this.state.promoCode}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Purchase Amount:</strong>
                          <br />${this.calculatePayPrice()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p style={style}>
                  <br />
                  You should print this page for your records.
                </p>
                <div style={style}>
                  <Button primary size={"huge"} color="red" as={Link} to={`/dashboard`}>
                    Open My Dashboard
                  </Button>
                  {this.unbounceJSInsert()}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </ContentContainer>
    );
  };

  billingForm = () => {
    const style = { textAlign: "center" };
    return (
      <ContentContainer>
        <div className="create-event">
          <Grid container>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <h2
                  className="section-title wow flipInX"
                  style={style}
                  data-wow-duration="0.35s"
                  data-wow-delay="0s"
                  data-wow-offset="0"
                >
                  Session Payment
                </h2>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={16} computer={13}>
                {this.purchaseBreakdown()}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={16} computer={13}>
                <Form
                  size={"large"}
                  loading={this.state.isLoading}
                  error={this.state.formErrors.length ? true : false}
                  className="minHeight"
                >
                  <Grid columns={1}>
                    <Grid.Column mobile={16} tablet={5} computer={5}>
                      <Form.Field required error={this.state.fieldErrors.billingCardType ? true : false}>
                        <label>Card Type</label>
                        <select
                          name="billingCardType"
                          placeholder="Select card type"
                          autoComplete="cc-type"
                          ref={this.fieldBillingCardType}
                          defaultValue={this.state.billingCardType}
                        >
                          {cardTypeOptions.map(cardTypeItem => {
                            return (
                              <option key={cardTypeItem.value} value={cardTypeItem.value}>
                                {cardTypeItem.text}
                              </option>
                            );
                          })}
                        </select>
                        <small>Type of card being used for payment.</small>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={5} computer={5}>
                      <Form.Field required error={this.state.fieldErrors.billingCardholderName ? true : false}>
                        <label>Cardholder Name</label>
                        <Input
                          placeholder="Cardholder Name"
                          type="text"
                          name="billingCardholderName"
                          autoComplete="cc-name"
                          maxLength="30"
                          disabled={this.state.isLoading}
                          ref={this.fieldBillingCardholderName}
                          defaultValue={this.state.billingCardholderName}
                        />
                        <small>Cardholder name as it appears on the card.</small>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={6} computer={6}>
                      <Form.Field required error={this.state.fieldErrors.billingCardNumber ? true : false}>
                        <label>Card Number</label>
                        <Input
                          placeholder="Card #"
                          type="text"
                          name="billingCardNumber"
                          autoComplete="cc-number"
                          maxLength="16"
                          disabled={this.state.isLoading}
                          ref={this.fieldBillingCardNumber}
                          defaultValue={this.state.billingCardNumber}
                        />
                        <small>Card number with no spaces or dashes.</small>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={6} computer={6}>
                      <Form.Field required error={this.state.fieldErrors.billingExpMonth ? true : false}>
                        <label>Expiration Month</label>
                        <select
                          name="billingExpMonth"
                          placeholder="Expiration Month"
                          autoComplete="cc-exp-month"
                          ref={this.fieldBillingExpMonth}
                          defaultValue={this.state.billingExpMonth}
                        >
                          {expirationMonthOptions.map(expItem => {
                            return (
                              <option key={expItem.value} value={expItem.value}>
                                {expItem.text}
                              </option>
                            );
                          })}
                        </select>
                        <small>Expiration month of the payment card.</small>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={5} computer={5}>
                      <Form.Field required error={this.state.fieldErrors.billingExpYear ? true : false}>
                        <label>Expiration Year</label>
                        <select
                          name="billingExpYear"
                          placeholder="Expiration Year"
                          autoComplete="cc-exp-year"
                          ref={this.fieldBillingExpYear}
                          defaultValue={this.state.billingExpYear}
                        >
                          {expirationYearOptions().map(expItem => {
                            return (
                              <option key={expItem.value} value={expItem.value}>
                                {expItem.text}
                              </option>
                            );
                          })}
                        </select>
                        <small>Expiration year of the payment card.</small>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={5} computer={5}>
                      <Form.Field required error={this.state.fieldErrors.billingCardCVC ? true : false}>
                        <label>Card Verification Code (CVC)</label>
                        <Input
                          placeholder="CVC #"
                          type="text"
                          name="billingCardCVC"
                          autoComplete="cc-csc"
                          maxLength="4"
                          disabled={this.state.isLoading}
                          ref={this.fieldBillingCardCVC}
                          defaultValue={this.state.billingCardCVC}
                        />
                        <small>The payment card's security code.</small>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={9} computer={11}>
                      <Image verticalAlign="middle" height="26" floated="left" src="/stripe_3x.png" />
                      <p className="smalltext">
                        Routine Rewire <strong>does not</strong> retain any of your payment information to keep you safe.
                      </p>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={7} computer={5}>
                      <Form.Field error={this.state.fieldErrors.fieldPromoCode ? true : false}>
                        <label>Promotion Code</label>
                        <Input
                          fluid
                          name="fieldPromoCode"
                          type="text"
                          ref={this.fieldPromoCode}
                          onChange={this.applyPromoCodeKeypress}
                          placeholder="Promo code"
                          maxLength="20"
                          autoComplete="off"
                          action
                        >
                          <input />
                          <Button content="Apply" onClick={this.checkPromoCode} />
                        </Input>
                        <small>Optional promo code</small>
                      </Form.Field>
                    </Grid.Column>

                    {this.errorSection()}
                    <Grid.Row centered>
                      <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Button
                          basic
                          color="red"
                          size={"large"}
                          floated={"left"}
                          as={Link}
                          to={`/session/${this.state.sessionID}`}
                          disabled={this.state.isLoading === true ? true : false}
                        >
                          Cancel
                        </Button>
                        <Button
                          primary
                          type="submit"
                          size={"large"}
                          floated={"right"}
                          onClick={this.processForm}
                          disabled={this.state.isLoading === true ? true : false}
                        >
                          {this.state.basePrice !== null ? `Pay $${this.calculatePayPrice()}` : "Purchase"}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </ContentContainer>
    );
  };

  render() {
    if (this.state.purchaseCompleted !== true) {
      return this.billingForm();
    } else if (this.state.purchaseCompleted === true && this.calculatePayPrice() === "0.00") {
      return this.fullPromotionCompleted();
    } else if (this.state.purchaseCompleted === true && this.calculatePayPrice() !== "0.00") {
      return this.purchaseCompleted();
    } else {
      return this.billingForm();
    }
  }
}

export default SessionPayment;
