import React, { Component } from "react";
import { slide as CanvasMenu } from "react-burger-menu";
import { Image } from 'semantic-ui-react';
import OffCanvasAuthorizedMenu from './OffCanvasAuthorizedMenu.js';
import OffCanvasGuestMenu from './OffCanvasGuestMenu.js';
import './OffCanvasMenu.css';

function ContextNav(props) {
  const {authorizedUser, appLoading, toggleOffCanvas} = props;
  if(appLoading === false) {
    if(authorizedUser === null) {
      return <OffCanvasGuestMenu toggleOffCanvas={toggleOffCanvas}/>
    } else {
      return <OffCanvasAuthorizedMenu toggleOffCanvas={toggleOffCanvas}/>
    }
  } else {
    return null;
  }
}

class OffCanvasMenu extends Component {
  render() {
    return (
      <CanvasMenu
        isOpen={this.props.isOpen}
        customBurgerIcon={false}
        onStateChange={this.props.onStateChange}
        width={'75%'}
      >
        <Image height="60" src="/logo120h-dark.png" />
        <ContextNav authorizedUser={this.props.authorizedUser} appLoading={this.props.appLoading} toggleOffCanvas={this.props.toggleOffCanvas}/>
        <small style={{color: "hsla(0, 0%, 100%, 0.27)"}}>v1.5</small>
      </CanvasMenu>
    );
  }
}

export default OffCanvasMenu;
