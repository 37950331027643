import React, { Component } from "react";
import { List } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";

class OffCanvasGuestMenu extends Component {
  render() {
    return (
      <List size="big">
        <List.Item
          as={Link}
          to="/account/register"
          onClick={this.props.toggleOffCanvas}>
          <List.Icon name="signup" color="red" />
          <List.Content>Register</List.Content>
        </List.Item>
        <List.Item
          as={Link}
          to="/account/sign-in"
          onClick={this.props.toggleOffCanvas}>
          <List.Icon name="sign-in" color="red" />
          <List.Content>Sign-in</List.Content>
        </List.Item>
      </List>
    );
  }
}

export default withRouter(OffCanvasGuestMenu);
