import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Container, Image } from 'semantic-ui-react'
import AuthorizedUserMenu from './AuthorizedUserMenu.js'
import GuestUserMenu from './GuestUserMenu.js'
import OffCanvasButton from './OffCanvasButton.js'
import './Navbar.css';

function ContextNav(props) {
  const {authorizedUser, appLoading} = props;
  if(appLoading === false) {
    if(authorizedUser === null) {
      return <GuestUserMenu />
    } else {
      return <AuthorizedUserMenu />
    }
  } else {
    return null;
  }
}


export default class Navbar extends Component {
  
  constructor(props) {
    super(props);
    this.state = { activeItem: 'logo' }
  }

  
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name }
  )}

    

  render() {
    return (
      <Container>
        <Menu size='large' secondary stackable={false}>
          <Menu.Item name='logo' className="navbar-logo" onClick={this.handleItemClick} as={Link} to="/"><Image height="60" src="/logo120h.png" /></Menu.Item>
          { /* 
            <Menu.Item name='home' className="navbar-link" active={activeItem === 'home' || activeItem === 'logo' } onClick={this.handleItemClick} as={Link} to="/" ><Icon name='home' color='red' />Home</Menu.Item>
            <Menu.Item name='events' className="navbar-link" active={activeItem === 'events'} onClick={this.handleItemClick} as={Link} to="/events"><Icon name='list' color='red' />Events</Menu.Item>
            */
          }
          <div className="desktop-nav-menu">
            <ContextNav authorizedUser={this.props.authorizedUser} appLoading={this.props.appLoading}/>
          </div>
          <div className="offcanvas-nav-menu">
            <OffCanvasButton toggleOffCanvas={this.props.toggleOffCanvas}/>
          </div>
        </Menu>
        
      </Container>
    )
  }
}
