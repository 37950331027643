import React, { Component } from 'react';
import { Menu, Dropdown, Icon } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import * as firebase from '../../../firebase';

class AuthorizedUserMenu extends Component {

    logOutUser = () => {
        firebase.signoutUser().then(() => {
            const {history} = this.props;
            //redirect to homepage
            history.push("/");
        }).catch(haserror => {
          this.setState({isLoading: false});
          this.setState({error: haserror});
        });
      }

  render() {
	return (
		<Menu.Menu position='right' className='right-side'>
            <Menu.Item name='home' className="navbar-link" as={Link} to="/dashboard" ><Icon name='cube' color='red' /> Dashboard</Menu.Item>
            <Dropdown item trigger={<span><Icon name='user circle' color='red'/> My Account</span>}>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/account/profile">Edit Profile</Dropdown.Item>
              <Dropdown.Item as={Link} to="/account/changepassword">Change Password</Dropdown.Item>
              <Dropdown.Item onClick={this.logOutUser}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
	)
  }
}

export default withRouter(AuthorizedUserMenu);