import React, { Component } from 'react';
import ContentContainer from '../../Layout/ContentContainer/ContentContainer.js';
import { Button, Form, Grid } from 'semantic-ui-react';
import { auth } from '../../../firebase/index.js';
import appConfig from '../../../appConfig.js';
import {WOW} from 'wowjs/dist/wow.js';

const INITIAL_STATE = {
  email: "",
  error: null,
  isLoading: false
}

class ForgotPassword extends Component {

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    document.title = `${appConfig.app.siteTitle} - Reset Password`;
  }

  componentDidMount = () => {
    new WOW({live: false}).init();
  }

  validateForm() {
    return (this.state.email.length > 0);
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const { history } = this.props;
    this.setState(prevState => ({
      error: null,
      isLoading: true
    }));
    auth.sendPasswordResetEmail(this.state.email.trim()).then(authUser => {
      this.setState(() => ({ ...INITIAL_STATE }));
      history.push("/account/action?result=passwordResetEmailSent");
    }).catch(haserror => {
      this.setState({ isLoading: false, error: haserror });
    });
  }

  render() {
    const style = { textAlign: 'center' };
    const errorStyle = { fontWeight: 'bold', color: '#a51a28', textAlign: 'center' }
    return (
      <ContentContainer>
        <div className="PasswordReset">
          <Grid container>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={10} computer={8}>
                <h2 className="section-title wow flipInX" style={style} data-wow-duration="0.35s" data-wow-delay="0s" data-wow-offset="0">Reset Your Password</h2>
                {this.state.error && <p style={errorStyle}>{this.state.error.message}</p>}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={10} computer={6}>
                <Form onSubmit={this.handleSubmit} loading={this.state.isLoading} size={"large"} className="wow fadeIn" data-wow-duration="0.35s" data-wow-delay="0s" data-wow-offset="0" data-wow-iteration="1">
                  <Form.Field>
                    <label>E-mail</label>
                    <input placeholder='E-mail' type='text' name='email' disabled={this.state.isLoading} value={this.state.email} onChange={this.handleChange} />
                  </Form.Field>
                  <Button primary type='submit' size={"medium"} floated={"right"} disabled={!this.validateForm() || this.state.isLoading}>Send Reset E-mail</Button>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </ContentContainer>
    );
  }
}

export default ForgotPassword;
