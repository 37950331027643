import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import ContentContainer from "../../Layout/ContentContainer/ContentContainer.js";
import { Button } from "semantic-ui-react";
import * as firebase from "../../../firebase/index.js";
import * as queryString from "qs";
import appConfig from "../../../appConfig.js";
import SimpleNotification from "../../Interface/SimpleNotification/SimpleNotification.js";

const INITIAL_STATE = {
  error: null,
  displayItem: "defaultWaiting",
  isLoading: true,
  pageHeading: "Just a moment...",
  pageContent: "",
  authUser: null,
  finalResult: null,
  formAction: null,
  pwResetCode: ""
};

class AccountAction extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    document.title = `${appConfig.app.siteTitle} - Account Actions`;
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };


  /* START FUNCTIONAL UI COMPONENTS ---------------- */
  buttonSignIn = () => {
    const pStyle = {paddingTop: "20px"};
    return (
      <div style={pStyle}>
      <Button size={"large"} basic color="red" as={Link} to="/account/sign-in">
        Sign into your account
      </Button>
      </div>
    );
  };

  buttonGotoDashboard = () => {
    const pStyle = {paddingTop: "20px"};
    return (
      <div style={pStyle}>
      <Button size={"large"} basic color="red" as={Link} to="/dashboard">
        Head over to your dashboard
      </Button>
      </div>
    );
  };
  
  buttonResendEmailVerification = () => {
    return (
      <Button size={"large"} basic color="red" onClick={this.resendVerifyEmail}>
        Re-send email verification
      </Button>
    );
  };
  
  msgAcctVerified = () => {
    const style = { textAlign: "center" };
    return (
      <div style={style}>
        <p>Your e-mail address has been successfully verified. Nice work!</p>
        {this.state.authUser !== null
          ? this.buttonGotoDashboard()
          : this.buttonSignIn()}
      </div>
    );
  };

  msgPasswordWasReset = () => {
    const style = { textAlign: "center" };
    return (
      <div style={style}>
        <p><strong>Your password was successfully updated.</strong></p>
        <p>You may now login with your new password.</p>
        {this.state.authUser !== null
          ? this.buttonGotoDashboard()
          : this.buttonSignIn()}
      </div>
    );
  };

  msgPasswordWasUpdated = () => {
    const style = { textAlign: "center" };
    return (
      <div style={style}>
        <p><strong>Your password was successfully updated.</strong></p>
        <p>Future logins will required you to use your new password.</p>
        {this.state.authUser !== null
          ? this.buttonGotoDashboard()
          : this.buttonSignIn()}
      </div>
    );
  };

  msgAcctPendingVerification = () => {
    //console.log("PENDING!");
    const style = { textAlign: "center" };
    return (
      <div style={style}>
        <p>
          <strong>
            Your e-mail address is currently pending verification.
          </strong>
        </p>
        <p>Please check your e-mail for the confirmation link.</p>
      </div>
    );
  };

  msgPasswordResetSent = () => {
    //console.log("PENDING!");
    const style = { textAlign: "center" };
    return (
      <div style={style}>
        <p>
          <strong>An e-mail has been sent to reset your password.</strong>
        </p>
        <p>Please check your e-mail and click the password reset link.</p>
      </div>
    );
  };

  msgAcctPendingResendOption = () => {
    //console.log("PENDING!");
    const style = { textAlign: "center" };
    return (
      <div style={style}>
        <p>
          <strong>
            Your e-mail address is currently pending verification.
          </strong>
        </p>
        <p>
          Please check your e-mail for the confirmation link. If you haven't
          received <br />
          the e-mail after 15 minutes, try re-sending the confirmation e-mail.
        </p>
        {this.buttonResendEmailVerification()}
      </div>
    );
  };

  /* END FUNCTIONAL UI COMPONENTS ---------------- */

  verifyEmailConfirmation = emailCode => {
    firebase
      .applyVerificationCode(emailCode)
      .then(() => {
        //code has been checked and verified...
        this.setState({displayItem: "authUserAcctVerified"});
      })
      .catch(haserror => {
        this.setState({displayItem: "acctVerificationError", error: haserror});
      });
  };

  verifyPasswordReset = emailCode => {
    const { history } = this.props;
    firebase
      .checkPWResetCode(emailCode)
      .then(() => {
        //code has been checked and verified...
        //this.setState({displayItem: "passwordResetCodeVerified", pwResetCode: emailCode});
        //const { history } = this.props;
        history.push(`/account/resetpassword?code=${emailCode}`);
      })
      .catch(haserror => {
        this.setState({displayItem: "passwordResetError", error: haserror});
      });
  };

  resendVerifyEmail = () => {
    //fire up the waiting screen first...
    this.setState({displayItem: "verifyEmailResending"});
    firebase
      .sendVerificationEmail()
      .then(() => {
        //const { history } = this.props;
        this.setState({displayItem: "unauthUserAcctUnverified"});
        //history.push("/account/action?result=pendingVerification");
      })
      .catch(haserror => {
        this.setState({displayItem: "acctVerificationError", error: haserror});
      });
  };

  componentWillUnmount() {
    this.removeListener();
  }

  componentDidMount() {
    this.removeListener = firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState(() => ({ authUser: authUser }))
        : this.setState(() => ({ authUser: null }));
    });
    this.LogicHandler();
  }

  LogicHandler = () => {
    const queryValues = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    var result = queryValues.result;
    var actionMode = queryValues.mode;
    var oobCode = queryValues.oobCode;
    if (result) {
      switch (result) {
        case "pendingVerificationDoubleCheck": {
          if (this.state.authUser !== null) {
            if (this.state.authUser.emailVerified) {
              this.setState({displayItem: "authUserAcctVerified"});
              break;
            } else {
              this.setState({displayItem: "unauthUserAcctUnverified"});
              break;
            }
          } else {
            this.setState({displayItem: "authUserAcctUnverified"});
            break;
          }
        }
        case "pendingVerification": {
          this.setState({displayItem: "unauthUserAcctUnverified"});
          break;
        }
        case "passwordWasReset": {
          this.setState({displayItem: "passwordWasReset"});
          break;
        }
        case "passwordWasUpdated": {
          this.setState({displayItem: "passwordWasUpdated"});
          break;
        }
        case "accountVerified": {
          this.setState({displayItem: "authUserAcctVerified"});
          break;
        }
        case "passwordResetEmailSent": {
          this.setState({displayItem: "passwordResetEmailSent"});
          break;
        }
        default: {
        }
      }
    } else if (actionMode && oobCode) {
      firebase
        .checkActionCode(oobCode)
        .then(result => {
          switch (result.operation) {
            //////////////////////////////////////////////////
            case "VERIFY_EMAIL": {
              this.setState({displayItem: "checkingEmailConfirmationCode"});
              this.verifyEmailConfirmation(oobCode);
              break;
            }
            //////////////////////////////////////////////////
            case "PASSWORD_RESET": {
              this.setState({displayItem: "checkingPasswordResetCode"});
              this.verifyPasswordReset(oobCode);
              break;
            }
            //////////////////////////////////////////////////
            default: {
            }
            //////////////////////////////////////////////////
          }
        })
        .catch(haserror => {
          //error from checking the code
          this.setState({displayItem: "codeCheckError", error: haserror})
        });
    }
  };
  
  DisplayHandler = () => {
    switch(this.state.displayItem) {
      case "authUserAcctVerified" : {
        return <SimpleNotification heading={"Account Verified"} content={this.msgAcctVerified()} />
      }
      case "authUserAcctUnverified" : {
        return <SimpleNotification heading={"Account Pending Verification"} content={this.msgAcctPendingResendOption()} />
      }
      case "unauthUserAcctUnverified" : {
        return <SimpleNotification heading={"Account Pending Verification"} content={this.msgAcctPendingVerification()} />
      }
      case "verifyEmailResending" : {
        return <SimpleNotification heading={"Re-sending Verification E-mail..."} loading={true} />
      }
      case "passwordResetEmailSent" : {
        return <SimpleNotification heading={"Password Reset E-mail Sent"} content={this.msgPasswordResetSent()} />
      }
      case "checkingEmailConfirmationCode" : {
        return <SimpleNotification heading={"Validating E-mail Address..."} loading={true} />
      }
      case "checkingPasswordResetCode" : {
        return <SimpleNotification heading={"Validating Password Reset..."} loading={true} />
      }
      case "defaultWaiting" : {
        return <SimpleNotification heading={"Just a moment..."} loading={true} />
      }
      case "acctVerificationError" : {
        return <SimpleNotification heading={"Account Verification Error"} error={this.state.error} />
      }
      case "passwordResetCodeVerified" : {
        return <SimpleNotification heading={"Password Reset Request Verified"} content={this.PasswordResetForm()} />
      }
      case "passwordWasReset" : {
        return <SimpleNotification heading={"Password Reset Complete"} content={this.msgPasswordWasReset()} />
      }
      case "passwordWasUpdated" : {
        return <SimpleNotification heading={"Password Updated Successfully"} content={this.msgPasswordWasUpdated()} />
      }
      case "passwordResetError" : {
        return <SimpleNotification heading={"Password Reset Error"} error={this.state.error} />
      }
      case "codeCheckError" : {
        return <SimpleNotification heading={"Validation Error"} error={this.state.error} />
      }
      default: {
        return <SimpleNotification heading={"Just a moment..."} loading={true} />
      }
    }
  }

  render() {
    return (
      <ContentContainer>
        <div className="AccountAction">
          {this.DisplayHandler()}
        </div>
      </ContentContainer>
    );
  }
}

export default withRouter(AccountAction);
