import React, { PureComponent } from "react";
import { Menu, Icon, Responsive, Grid, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";

class SecondaryMenu extends PureComponent {
  
  render() {
    if (!this.props.visible) {
      return null;
    } else {
      return (
        <Grid container>
          <Grid.Row centered>
            <Grid.Column mobile={16} tablet={15} computer={15} className="no-side-padding">
              <div className="secondary-menu">
                <Menu secondary pointing widths={"4"} size={"large"}>
                  <Menu.Item
                    name="overview"
                    disabled={this.props.disabled}
                    active={this.props.subMenuName === "overview"}
                    as={Link}
                    to={`/session/${this.props.sessionID}`}
                  >
                    <Responsive minWidth={"560"}>
                      <Icon name="file alternate outline" />
                    </Responsive>
                    Overview
                  </Menu.Item>
                  <Menu.Item
                    name="habits"
                    disabled={this.props.disabled}
                    active={this.props.subMenuName === "habits"}
                    as={Link}
                    to={`/session/${this.props.sessionID}/habits`}
                  >
                    <Responsive minWidth={"560"}>
                      <Icon name="numbered list" />
                    </Responsive>
                    Habits
                  </Menu.Item>
                  <Menu.Item
                    name="teams"
                    disabled={this.props.disabled}
                    active={this.props.subMenuName === "teams" && !this.props.disabled}
                    as={Link}
                    to={`/session/${this.props.sessionID}/teams`}
                  >
                    <Responsive minWidth={"560"}>
                      <Icon name="group" />
                    </Responsive>
                    Teams{this.props.pendingTeamRequests !== 0 && this.props.pendingTeamRequests !== null ? <Label size={"mini"} className="labelmargin" circular color={"red"}>{this.props.pendingTeamRequests}</Label> : null}
                  </Menu.Item>
                  <Menu.Item
                    name="rankings"
                    disabled={this.props.disabled}
                    active={this.props.subMenuName === "rankings"}
                    as={Link}
                    to={`/session/${this.props.sessionID}/rankings`}
                  >
                    <Responsive minWidth={"560"}>
                      <Icon name="chart bar" />
                    </Responsive>
                    Rankings
                  </Menu.Item>
                </Menu>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
  }
}

export default SecondaryMenu;
