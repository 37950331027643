import React, { Component } from "react";
/* import logo from './logo.svg'; */
import "./Homepage.css";
import appConfig from "../../appConfig.js";
import { Link } from "react-router-dom";
import * as moment from "moment-timezone";
import ContentContainer from "../Layout/ContentContainer/ContentContainer.js";
import { Form, Grid, Card } from "semantic-ui-react";
import { WOW } from "wowjs/dist/wow.js";
import { db } from "../../firebase/index.js";

const INITIAL_STATE = {
  error: null,
  isLoading: true,
  upcomingSessions: [],
  currentSessions: [],
  pastSessions: []
};

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    document.title = `${appConfig.app.siteTitle}`;

    /* if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log("DEVELOPMENT");
    } else {
      console.log("PRODUCTION");
    } */
  };

  sessionCard = currentItem => {
    var startDateMoment = moment.unix(currentItem.startDate);
    var endDateMoment = moment.unix(currentItem.endDate);

    var niceStartDate = startDateMoment.format("L");
    var niceEndDate = endDateMoment.format("L");
    var registrationEndMoment = moment.unix(currentItem.startDate).subtract(5,'days');

    var todayMoment = moment.tz(moment.tz.guess());

    var eventHasStarted = startDateMoment.isSameOrBefore(todayMoment);

    var startedDaysFromNow = Math.abs(startDateMoment.diff(todayMoment, "days"));
    var startedHoursFromNow = Math.abs(startDateMoment.diff(todayMoment, "hours"));
    var hoursFromRegistrationDeadline = registrationEndMoment.diff(todayMoment, "hours");
    
    var endedHoursFromNow = Math.abs(endDateMoment.diff(todayMoment, "hours"));
    var endedDaysFromNow = Math.abs(endDateMoment.diff(todayMoment, "days"));

    
    var registrationDaysFromNowDays = registrationEndMoment.diff(todayMoment, "days");
    
    var registrationDaysFromNowMinutes = registrationEndMoment.diff(todayMoment, "minutes");
    var registrationHasEnded = todayMoment.isAfter(registrationEndMoment);

    
    var alreadyEnded = todayMoment.isSameOrAfter(endDateMoment);
    
    var duration = endDateMoment.diff(startDateMoment, "day") + 1;

    var dateContext = "";

    if(!eventHasStarted) {
      if(startedHoursFromNow <= 48 && startedHoursFromNow > 0) {
        dateContext += "Starts in " + (startedHoursFromNow) + " hour(s)";
      } else {
        dateContext += "Starts in " + (startedDaysFromNow) + " days(s)";
      }
      if(!registrationHasEnded) {
        if(hoursFromRegistrationDeadline <= 48 && hoursFromRegistrationDeadline > 1) {
          dateContext += "\nRegistration ends in " + (hoursFromRegistrationDeadline) + " hour(s)";
        } else if(hoursFromRegistrationDeadline <= 1) {
          dateContext += "\nRegistration ends in " + (registrationDaysFromNowMinutes) + " minute(s)";
        } else {
          dateContext += "\nRegistration ends in " + (registrationDaysFromNowDays) + " day(s)";
        }
      } else {
        if(hoursFromRegistrationDeadline < 0 && hoursFromRegistrationDeadline > -48) {
          dateContext += "\nRegistration ended " + (Math.abs(hoursFromRegistrationDeadline)) + " hour(s) ago";
        } else {
          dateContext += "\nRegistration ended " + (Math.abs(registrationDaysFromNowDays)) + " day(s) ago";
        }
      }
    } else {
      if(alreadyEnded) {
        if(endedHoursFromNow < 0 && endedHoursFromNow >= -48) {
          dateContext += "Ended " + (Math.abs(endedHoursFromNow)) + " hour(s) ago.";
        } else {
          dateContext += "Ended " + (Math.abs(endedDaysFromNow)) + " day(s) ago.";
        }
      } else {
        if(startedHoursFromNow < 0 && startedHoursFromNow >= -48) {
          dateContext += "Started " + (Math.abs(startedHoursFromNow)) + " hour(s) ago.";
        } else {
          if(Math.abs(startedDaysFromNow) === 0) {
            dateContext += "Started today.";  
          } else {
            dateContext += "Started " + (Math.abs(startedDaysFromNow)) + " day(s) ago.";
          }
          
        }
      }
    }
    

    return (
      <Card
        key={currentItem.id}
        as={Link}
        to={`/session/${currentItem.id}`}
        raised
        fluid
      >
        <Card.Content>
          <Card.Header>
            <div className="table-label-wrapper">
              <table className="table-label inline-block">
                <tbody>
                  <tr>
                    <td>
                      <h2 className="nomargins subtle-heading">Starts:</h2>
                    </td>
                    <td>
                      <h2 className="nomargins">{niceStartDate}</h2>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h2 className="nomargins subtle-heading">Ends:</h2>
                    </td>
                    <td>
                      <h2 className="nomargins">{niceEndDate}</h2>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h2 className="nomargins subtle-heading">Duration:</h2>
                    </td>
                    <td>
                      <h2 className="nomargins">{duration} day(s)</h2>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card.Header>
          <Card.Meta>
            <pre className="center-text nomargins">{dateContext}</pre>
          </Card.Meta>
        </Card.Content>
      </Card>
    );
  };

  sessionList = sessionArray => {
    return (
      <Card.Group itemsPerRow={2} stackable centered>
        {sessionArray.map(sessionItem => {
          return this.sessionCard(sessionItem);
        })}
      </Card.Group>
    );
  };

  componentDidMount = () => {
    new WOW({ live: false }).init();

    const currentTime = moment().tz("America/New_York");
    const currentTimeUnix = currentTime.unix();
    const pastmonth = currentTime.subtract(30, "days");
    const pastmonthUnix = pastmonth.unix();

    var sessionsRef = db.collection("rewireSessions");
    sessionsRef
      .where("endDate", ">=", pastmonthUnix)
      .get()
      .then(data => {
        if (data.docs.length > 0) {
          var upcoming = [];
          var ongoing = [];
          var past = [];
          data.docs.forEach(doc => {
            let docData = doc.data();
            docData.id = doc.id;
            if (docData.endDate < currentTimeUnix) {
              past.push(docData);
            } else if (
              docData.startDate < currentTimeUnix &&
              docData.endDate > currentTimeUnix
            ) {
              ongoing.push(docData);
            } else if (docData.startDate > currentTimeUnix) {
              upcoming.push(docData);
            }
          });
          this.setState({
            isLoading: false,
            currentSessions: [...ongoing],
            upcomingSessions: [...upcoming],
            pastSessions: [...past]
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false, error: error });
      });
  };

  gridSegment = (sectionTitle, dataArray) => {
    const marginStyle = { marginBottom: "20px" };
    const noTopPadding = { paddingTop: "0px" };
    const smallMargin = {marginBottom: "5px", textAlign: "center"};
    if (dataArray.length) {
      return (
        <Grid style={marginStyle}>
          <Grid.Row centered>
            <Grid.Column mobile={16} tablet={10} computer={12}>
              <h2 className="section-title flipInX" style={smallMargin}>
                {sectionTitle}
              </h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered style={noTopPadding}>
            <Grid.Column mobile={16} tablet={16} computer={12}>
              <Grid columns={1}>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {this.sessionList(dataArray)}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
    return null;
  };

  render() {
    return (
      <ContentContainer>
        <div className="HomePage">
          <Grid container>
            <Grid.Row centered>
              <Form
                autoComplete="off"
                loading={this.state.isLoading}
                size={"large"}
                className="wow fadeIn fullwidth minHeight"
                data-wow-duration="0.35s"
                data-wow-delay="0s"
                data-wow-offset="0"
                data-wow-iteration="1"
              >
                {this.gridSegment(
                  "Upcoming Sessions",
                  this.state.upcomingSessions
                )}
                {this.gridSegment(
                  "Ongoing Sessions",
                  this.state.currentSessions
                )}
                {this.gridSegment(
                  "Past Sessions (30 days)",
                  this.state.pastSessions
                )}
              </Form>
            </Grid.Row>
          </Grid>
        </div>
      </ContentContainer>
    );
  }
}

export default Homepage;
