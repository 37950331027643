import React, { Component } from 'react';
import { Button, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
class GuestUserMenu extends Component {

  render() {
	return (
		<Menu.Menu position='right' className='right-side'>
            <Menu.Item>
                <Button primary as={Link} to="/account/register">Register</Button>
            </Menu.Item>

            <Menu.Item>
                <Button basic color='red' as={Link} to="/account/sign-in">Sign-in</Button>
            </Menu.Item>
        </Menu.Menu>
	)
  }
}

export default GuestUserMenu;