import React, { Component } from "react";
import { Grid, Image, Transition, Segment, Icon } from "semantic-ui-react";

class HabitTile extends Component {
  constructor(props) {
    super(props);
    this.state = { actionWorking: false };
  }

  startAction = index => {
    //toggleActionWorking
    this.setState({ actionWorking: true });
    this.props.clickAction(index);
  };

  habitIcon = (icon, index) => {
    return (
      <Image
        src={`/icons/${icon}`}
        centered
        className="click-image"
        onClick={() => this.props.visible && this.startAction(index)}
      />
    );
  };

  workingIcon = () => {
    return (
      <Icon
      size="massive"
        name="check circle"
        className="click-image"
      />
    );
  };

  render() {
    const animation = "zoom";
    const duration = 500;

    return (
      <Transition
        animation={animation}
        duration={duration}
        transitionOnMount={true}
        reactKey={this.props.index}
        visible={this.props.visible}
        unmountOnHide={true}
      >
        <Grid.Column mobile={8} tablet={8} computer={5} className="tile-column">
          <Segment
            className={this.state.actionWorking ? "center-text tile tile-highlight" : "center-text tile"}
            disabled={!this.props.visible}
          >
            <div className="icon-wrapper">
            {this.state.actionWorking ? this.workingIcon() : this.habitIcon(this.props.icon, this.props.index)}
            </div>
            <div className="habit-title-wrapper topmarginp5">
              <h3 className="habit-title">{this.props.text}</h3>
            </div>
          </Segment>
        </Grid.Column>
      </Transition>
    );
  }
}

export default HabitTile;
