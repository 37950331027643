import React, { Component } from "react";
/* import logo from './logo.svg'; */
import appConfig from "../../appConfig.js";
import ContentContainer from "../Layout/ContentContainer/ContentContainer.js";
import { Form, Grid, Button, Icon } from "semantic-ui-react";
import { functions } from "../../firebase/index.js";

const INITIAL_STATE = {
  error: null,
  isLoading: false
};

class Testing extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    document.title = `${appConfig.app.siteTitle}`;
  };

  populateActivites = () => {
      this.setState({isLoading: true});
    var populateSession = functions.httpsCallable("populateActivities");
    populateSession({sessionID: "oD9X9RySECiV7GZOkBvo"}).then(result => {
        this.setState({isLoading: false});
    }).catch(error => {
        this.setState({isLoading: false});
        console.log(error);
    })
  }

  render() {
    return (
      <ContentContainer>
        <div className="Testing">
          <Grid container>
            <Grid.Row centered>
              <Form
                autoComplete="off"
                loading={this.state.isLoading}
                size={"large"}
                className="wow fadeIn fullwidth minHeight"
              >
                <Button
                  basic
                  size={"large"}
                  color={"red"}
                  disabled={this.state.isLoading}
                  onClick={this.populateActivites}
                >
                  <Icon name={"check"} />
                  Populate Activities
                </Button>
              </Form>
            </Grid.Row>
          </Grid>
        </div>
      </ContentContainer>
    );
  }
}

export default Testing;
