import React, { Component } from "react";
import { List } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import * as firebase from "../../../firebase";

class OffCanvasAuthorizedMenu extends Component {
  logOutUser = () => {
    firebase
      .signoutUser()
      .then(() => {
        const { history } = this.props;
        //redirect to homepage
        history.push("/");
      })
      .catch(haserror => {
        this.setState({ isLoading: false });
        this.setState({ error: haserror });
      });
  };

  logOutClick = () => {
    this.props.toggleOffCanvas();
    this.logOutUser();
  }

  render() {
    return (
      <List size="big">
        <List.Item
          as={Link}
          to="/dashboard"
          onClick={this.props.toggleOffCanvas}>
          <List.Icon name="dashboard" color="red" />
          <List.Content>Dashboard</List.Content>
        </List.Item>
        <List.Item
          as={Link}
          to="/account/profile"
          onClick={this.props.toggleOffCanvas}>
          <List.Icon name="user" color="red" />
          <List.Content>Update Profile</List.Content>
        </List.Item>
        <List.Item
          as={Link}
          to="/account/changepassword"
          onClick={this.props.toggleOffCanvas}>
          <List.Icon name="user secret" color="red" />
          <List.Content>Change Password</List.Content>
        </List.Item>
        <List.Item
          as={Link}
          to="/account/sign-in"
          onClick={this.logOutClick}>
          <List.Icon name="sign-out" color="red" />
          <List.Content>Logout</List.Content>
        </List.Item>
      </List>
    );
  }
}

export default withRouter(OffCanvasAuthorizedMenu);
