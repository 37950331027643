import React, { PureComponent } from "react";
import { Grid, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ContentContainer from "../Layout/ContentContainer/ContentContainer.js";

class SessionVisitorLogin extends PureComponent {
  render() {
    const style = { textAlign: "center" };
    const spacing = { width: "20px", display: "inline-block" };
    if (!this.props.isLoading) {
      return (
        <ContentContainer>
          <div className="error-404">
            <Grid container>
              <Grid.Row centered columns={1}>
                <Grid.Column>
                  <p style={style}>You must sign-in or register before you can participate in this session.</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns={1}>
                <Grid.Column>
                  <div style={style}>
                    <Button primary size={"large"} as={Link} to="/account/register">
                      Register
                    </Button>
                    <div style={spacing}> </div>
                    <Button basic size={"large"} color="red" as={Link} to="/account/sign-in">
                      Sign-in
                    </Button>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </ContentContainer>
      );
    } else {
      return null;
    }
  }
}

export default SessionVisitorLogin;
