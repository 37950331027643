import React, { Component } from 'react';
import Navbar from '../Navbar/Navbar'

class Header extends Component {

  render() {
	return (
      <div className="navbar">
        <Navbar authorizedUser={this.props.authorizedUser} appLoading={this.props.appLoading} history={this.props.history} toggleOffCanvas={this.props.toggleOffCanvas}/>
      </div>
    )
  }
}

export default Header;