import React, { Component } from "react";
import ContentContainer from "../../Layout/ContentContainer/ContentContainer.js";
import { Button, Form, Grid } from "semantic-ui-react";
import * as firebase from "../../../firebase/index.js";
import appConfig from "../../../appConfig.js";
import { WOW } from "wowjs/dist/wow.js";

const INITIAL_STATE = {
  newpassword: "",
  confirmpassword: "",
  error: null,
  isLoading: false
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    document.title = `${appConfig.app.siteTitle} - Change Password`;
  };

  componentDidMount() {
    new WOW({ live: false }).init();
  }

  validateForm() {
    return (
      this.state.newpassword.length > 0 &&
      this.state.confirmpassword.length > 0 &&
      this.state.newpassword === this.state.confirmpassword
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { history } = this.props;
    //clear out previous error states
    this.setState(prevState => ({
      error: null,
      isLoading: true
    }));

    firebase
      .changePassword(this.state.newpassword.trim())
      .then(success => {
        this.setState(prevState => ({
            isLoading: false
          }));
        history.push(`/account/action?result=passwordWasUpdated`);
      })
      .catch(haserror => {
        this.setState(prevState => ({
            isLoading: false,
            error: haserror
        }));
        switch (haserror.code) {
          case "auth/requires-recent-login": {
            break;
          }
          default: {
          }
        }
        this.setState({ error: haserror });
      });
  };

  changePWForm = () => {
    return (
        <Form
          onSubmit={this.handleSubmit}
          loading={this.state.isLoading}
          size={"large"}
          className="wow fadeIn"
          data-wow-duration="0.35s"
          data-wow-delay="0s"
          data-wow-offset="0"
          data-wow-iteration="1"
        >
          <Form.Field>
            <label>New Password</label>
            <input
              placeholder="New Password"
              type="password"
              name="newpassword"
              disabled={this.state.isLoading}
              value={this.state.newpassword}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Confirm New Password</label>
            <input
              placeholder="Confirm New Password"
              type="password"
              name="confirmpassword"
              disabled={this.state.isLoading}
              value={this.state.confirmpassword}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Button
            primary
            type="submit"
            size={"medium"}
            disabled={!this.validateForm() || this.state.isLoading}
          >
            Change Password
          </Button>
        </Form>
    );
  };

  reauthForm = () => {
    const style = { textAlign: "center" };
    return (
      <Form style={style} size={"large"} loading={this.state.isLoading}>
        <Button primary type='submit' size={"large"} onClick={this.logOutAndReauth}>
        Logout &amp; Reauthenticate
      </Button>
      </Form>
    );
  };

  logOutAndReauth = () => {
    firebase.signoutUser().then(() => {
        const {history} = this.props;
        //redirect to homepage
        history.push("/account/changepassword");
    }).catch(haserror => {
      this.setState({isLoading: false});
      this.setState({error: haserror});
    });
  }

  render() {
    const style = { textAlign: "center" };
    const errorStyle = {
      fontWeight: "bold",
      color: "#a51a28",
      textAlign: "center"
    };
    return (
      <ContentContainer>
        <div className="ChangePassword">
          <Grid container>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={10} computer={8}>
                <h2
                  className="section-title wow flipInX"
                  style={style}
                  data-wow-duration="0.35s"
                  data-wow-delay="0s"
                  data-wow-offset="0"
                >
                  Change Your Password
                </h2>
                {this.state.error && (
                  <p style={errorStyle}>{this.state.error.message}</p>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={10} computer={6}>
                {this.state.error && this.state.error.code === 'auth/requires-recent-login' ? this.reauthForm() : this.changePWForm() }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </ContentContainer>
    );
  }
}

export default ChangePassword;
