import React, { Component } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
class OffCanvasButton extends Component {
  
  render() {
	return (
		<Menu.Menu position='right' className='right-side'>
            <Menu.Item>
                <Icon name='bars' size='big' link={true} onClick={this.props.toggleOffCanvas} />
            </Menu.Item>
        </Menu.Menu>
	)
  }
}

export default OffCanvasButton;