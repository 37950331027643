import React, { PureComponent } from "react";

class SessionOverviewHeading extends PureComponent {
  pageTitle = () => {
    const style = { textAlign: "center" };
    return (
      <h1 className="section-title nomargins" style={style}>
        <span>Routine Rewire</span>
      </h1>
    );
  };

  dateRange = () => {
    const style = {
      fontWeight: "bold",
      color: "#118689",
      textAlign: "center"
    };
    return (
      <h2 className="section-title nomargins" style={style}>
        <span>
          {this.props.startDate} - {this.props.endDate}
        </span>
      </h2>
    );
  };

  dateContext = () => {
    const style = {
      fontWeight: "bold",
      color: "#00000066",
      textAlign: "center"
    };
    return (
      <h3 className="section-title nomargins" style={style}>
        <span>{this.props.dateContext}</span>
      </h3>
    );
  };

  render() {
    if (!this.props.isLoading && this.props.startDate && this.props.endDate && this.props.dateContext) {
      return (
        <div>
          {this.pageTitle()}
          {this.props.startDate && this.props.endDate && this.dateRange()}
          {this.props.dateContext && this.dateContext()}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default SessionOverviewHeading;
