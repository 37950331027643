import React, { Component } from "react";
import ContentContainer from "../Layout/ContentContainer/ContentContainer.js";
import appConfig from "../../appConfig.js";
import * as moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Form, Grid, Card, Button } from "semantic-ui-react";
import { WOW } from "wowjs/dist/wow.js";
import { db, auth } from "../../firebase/index.js";

const INITIAL_STATE = {
  error: null,
  isLoading: true,
  upcomingSessions: [],
  currentSessions: [],
  pastSessions: [],
  hasNoPurchases: false
};

var unsubscribeFromAuthChanges = null;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    document.title = `${appConfig.app.siteTitle} - My Dashboard`;
  };

  componentWillUnmount = () => {
    if (unsubscribeFromAuthChanges !== null) {
      unsubscribeFromAuthChanges();
    }
  };

  getEnrolledSessions = () => {
    const { history } = this.props;
    const currentTime = moment().tz("America/New_York");
    const currentTimeUnix = currentTime.unix();
    //const pastmonth = currentTime.subtract(30, "days");

    var sessionsRef = db.collection("rewireSessions");
    sessionsRef
      .where("enrolledUsers", "array-contains", auth.currentUser.uid)
      .get()
      .then(data => {
        if (data.docs.length > 0) {
          var upcoming = [];
          var ongoing = [];
          var past = [];
          data.docs.forEach(doc => {
            let docData = doc.data();
            docData.id = doc.id;
            if (docData.endDate < currentTimeUnix) {
              past.push(docData);
            } else if (
              docData.startDate < currentTimeUnix &&
              docData.endDate > currentTimeUnix
            ) {
              ongoing.push(docData);
            } else if (docData.startDate > currentTimeUnix) {
              upcoming.push(docData);
            }
          });
          var willRedirect = false;
          if (ongoing.length === 1 && upcoming.length === 0) {
            history.push(`/session/${ongoing[0].id}/habits`);
            willRedirect = true;
          } else if (ongoing.length === 0 && upcoming.length === 1) {
            history.push(`/session/${upcoming[0].id}/habits`);
            willRedirect = true;
          }
          if(willRedirect === false) {
            this.setState({
              isLoading: false,
              currentSessions: [...ongoing],
              upcomingSessions: [...upcoming],
              pastSessions: [...past]
            });
          }
        } else {
          this.setState({ isLoading: false, hasNoPurchases: true });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false, error: error });
      });
  }

  componentDidMount = () => {
    new WOW({ live: false }).init();

    unsubscribeFromAuthChanges = auth.onAuthStateChanged(authUser => {
      if (authUser) {
        const { history } = this.props;
        var profileReference = db.collection("userProfiles").doc(auth.currentUser.uid);
        profileReference
          .get()
          .then(doc => {
            if (doc.exists) {
              var docData = doc.data();
              if (!docData.updatedAt) {
                history.push("/account/profile");
              } else {
                this.getEnrolledSessions();
              }
            } else {
              history.push("/account/profile");
            }
          })
          .catch(error => {
            this.setState({ isLoading: false, error: error });
          });
      }
    });
  };

  sessionCard = currentItem => {
    var startDateMoment = moment.unix(currentItem.startDate).tz("America/New_York");
    var endDateMoment = moment.unix(currentItem.endDate).tz("America/New_York");

    var niceStartDate = startDateMoment.format("L");
    var niceEndDate = endDateMoment.format("L");

    var todayMoment = moment.tz();

    var alreadyStarted = startDateMoment.isBefore(todayMoment);
    var alreadyEnded = endDateMoment.isBefore(todayMoment);
    var daysFromNow = Math.abs(startDateMoment.diff(todayMoment, "days"));
    var daysSinceEnd = endDateMoment.fromNow();
    var duration = endDateMoment.diff(startDateMoment, "day") + 1;

    var dateContext = "";
    if (!alreadyStarted && !alreadyEnded) {
        if(daysFromNow < 1) {
          dateContext =
        "Starts tomorrow "
        } else {
          dateContext =
        "Starts in " +
        daysFromNow +
        " day(s)\n Registration ends in " +
        (daysFromNow - 1) +
        " day(s)";
        }
      
    } else if (alreadyStarted && !alreadyEnded) {
      dateContext = `Started ${daysFromNow} day(s) ago. Ends ${daysSinceEnd}.`;
    } else if (alreadyEnded) {
      dateContext = `Ended ${daysSinceEnd}.`;
    }
    return (
      <Card
        key={currentItem.id}
        as={Link}
        to={`/session/${currentItem.id}`}
        raised
        fluid
      >
        <Card.Content>
          <Card.Header>
            <div className="table-label-wrapper">
              <table className="table-label">
                <tbody>
                  <tr>
                    <td>
                      <h2 className="nomargins subtle-heading">Starts:</h2>
                    </td>
                    <td>
                      <h2 className="nomargins">{niceStartDate}</h2>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h2 className="nomargins subtle-heading">Ends:</h2>
                    </td>
                    <td>
                      <h2 className="nomargins">{niceEndDate}</h2>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h2 className="nomargins subtle-heading">Duration:</h2>
                    </td>
                    <td>
                      <h2 className="nomargins">{duration} day(s)</h2>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card.Header>
          <Card.Meta>
            <pre className="center-text nomargins">{dateContext}</pre>
          </Card.Meta>
        </Card.Content>
      </Card>
    );
  };

  sessionList = sessionArray => {
    return (
      <Card.Group itemsPerRow={2} stackable centered>
        {sessionArray.map(sessionItem => {
          return this.sessionCard(sessionItem);
        })}
      </Card.Group>
    );
  };

  gridSegment = (sectionTitle, dataArray) => {
    const marginStyle = { marginBottom: "20px" };
    const noTopPadding = { paddingTop: "0px" };
    const smallMargin = { marginBottom: "5px", textAlign: "center" };
    if (dataArray.length) {
      return (
        <Grid style={marginStyle}>
          <Grid.Row centered>
            <Grid.Column mobile={16} tablet={10} computer={12}>
              <h2 className="section-title flipInX" style={smallMargin}>
                {sectionTitle}
              </h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered style={noTopPadding}>
            <Grid.Column mobile={16} tablet={16} computer={12}>
              <Grid columns={1}>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {this.sessionList(dataArray)}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
    return null;
  };
  
  noEnrollmentsMessage = () => {
    const centerText = {textAlign: "center"}
    if((!this.state.isLoading) && (this.state.upcomingSessions.length === 0) && (this.state.currentSessions.length === 0))
    {
      return (
        <Grid>
          <Grid.Row centered>
            <Grid.Column mobile={16} tablet={10} computer={12}>
              <h2 className="section-title flipInX" style={centerText}>
                It looks like you aren't enrolled in any sessions.
              </h2>
              <p className="section-title flipInX" style={centerText}>
                Please check out the upcoming sessions and select one to enroll in.
              </p>
              
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column mobile={16} tablet={10} computer={12}>
              <div style={centerText}>
              <Button
                    primary
                    size={"huge"}
                    color="red"
                    as={Link}
                    to={`/`}
                  >
                    View Upcoming Sessions
                  </Button>
                  </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    } else {
      return null;
    }
      
  };

  render() {
    return (
      <ContentContainer>
        <div className="DashboardPage">
          <Grid container>
            <Grid.Row centered>
              <Form
                autoComplete="off"
                loading={this.state.isLoading}
                size={"large"}
                className="wow fadeIn fullwidth minHeight"
                data-wow-duration="0.35s"
                data-wow-delay="0s"
                data-wow-offset="0"
                data-wow-iteration="1"
              >
                {this.gridSegment(
                  "My Upcoming Sessions",
                  this.state.upcomingSessions
                )}
                {this.gridSegment(
                  "My Ongoing Sessions",
                  this.state.currentSessions
                )}
                {this.gridSegment("My Past Sessions", this.state.pastSessions)}
                
                {this.noEnrollmentsMessage()}
              </Form>
            </Grid.Row>
          </Grid>
        </div>
      </ContentContainer>
    );
  }
}

export default Dashboard;
