import appConfig from "../appConfig.js";
import firebase from 'firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/functions';

firebase.initializeApp(appConfig.firebase);

export const auth = firebase.auth();

export const functions = firebase.functions();

export const isAuthenticated = () => {
    return !!auth.currentUser
};

//const firestoreSettings = {timestampsInSnapshots: true};
export const db = firebase.firestore();
//db.settings(firestoreSettings);

export const dbAdmin = firebase.firestore;

export const createUser = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
 }
 export const sendVerificationEmail = () => {
     return auth.currentUser.sendEmailVerification();
 }
 export const checkActionCode = (code) => {
     return auth.checkActionCode(code);
 }
 export const applyVerificationCode = (code) => {
     return auth.applyActionCode(code);
 }
 export const signinUser = (email, password) => {
     return auth.signInWithEmailAndPassword(email, password);
 }
 export const signoutUser = () => {
     return auth.signOut();
 }
 export const sendPasswordResetEmail = (email) => {
     return auth.sendPasswordResetEmail(email);
 }
 export const checkPWResetCode = (code) => {
     return auth.verifyPasswordResetCode(code);
 }
 export const resetPassword = (code, newpassword) => {
     return auth.confirmPasswordReset(code, newpassword);
 }
 export const changePassword = (password) => {
     return auth.currentUser.updatePassword(password);
 }
 export const reAuthenticate = () => {
     return auth.currentUser.reauthenticateAndRetrieveDataWithCredential();
 }