import React, { Component } from "react";
import appConfig from "../../appConfig.js";
import * as moment from "moment-timezone";
import ContentContainer from "../Layout/ContentContainer/ContentContainer.js";
import { Form, Grid, Button, Icon, Label, Segment, Input, Message, List, Modal, Header, Table } from "semantic-ui-react";
import { db, auth, functions, dbAdmin } from "../../firebase/index.js";
import { Link } from "react-router-dom";
import SecondaryMenu from "./SecondaryMenu.js";
import SessionOverviewHeading from "./SessionOverviewHeading.js";
import SessionNotFound from "./SessionNotFound.js";
import SessionVisitorLogin from "./SessionVisitorLogin.js";
import PageVisibility from "react-page-visibility";
import HabitTile from "../Habits/HabitTile.js";

const INITIAL_STATE = {
  error: null,
  isLoading: true,
  sessionPageHeading: null,
  sessionNotFound: false,
  sessionStartDate: null,
  sessionEndDate: null,
  sessionOngoing: null,
  sessionID: null,
  sessionPrice: null,
  sessionMembers: [],
  sessionScoreData: [],
  sessionIndividualScores: [],
  sessionTeamScores: [],
  sessionActivities: [],
  sessionOver: null,
  dateContext: "",
  teamData: [],
  formErrors: [],
  fieldErrors: {},
  showTeamMembersTable: false,
  modalShown: null,
  completedHabits: null,
  userProfile: null,
  allUserProfiles: null,
  showScoresFor: "individuals"
  /* viewingHabitDate: moment().startOf('day').unix() */
  /*   viewingHabitDate: this.props.epocDate || moment().startOf('day').unix() */
};

var unsubscribeRankings = null;
var unsubscribeEvent = null;
var unsubscribeProfiles = null;

class SessionPage extends Component {
  constructor(props) {
    super(props);
    const { epocDate } = this.props.match.params;
    if (epocDate !== "undefined" && epocDate !== undefined) {
      this.state = { viewingHabitDate: epocDate, ...INITIAL_STATE };
    } else {
      this.state = {
        viewingHabitDate: moment()
          .tz("America/New_York")
          .startOf("day")
          .unix(),
        ...INITIAL_STATE
      };
      /* this.state = {viewingHabitDate: 1547355600, ...INITIAL_STATE}; */
    }

    this.fieldTeamCreate = React.createRef();
    this.fieldTeamSelect = React.createRef();
  }

  pageLoading = () => {
    return this.state.isLoading || this.props.appLoading;
  };

  showCurrentErrors = section => {
    if (this.state.error && this.state.error[section]) {
      return (
        <Grid.Column mobile={16} tablet={12} computer={9} className={"extrapaddingtopbottom"}>
          <Message error>
            <Message.Content>{this.state.error[section]}</Message.Content>
          </Message>
        </Grid.Column>
      );
    }
  };

  componentWillUnmount = () => {
    if (unsubscribeRankings !== null) {
      unsubscribeRankings();
    }
    if (unsubscribeEvent !== null) {
      unsubscribeEvent();
    }
    if (unsubscribeProfiles !== null) {
      unsubscribeProfiles();
    }
  };

  componentWillMount = () => {
    document.title = `${appConfig.app.siteTitle} - Session Details`;
  };

  componentDidMount = () => {
    const { sessionID } = this.props.match.params;
    //isLoading is already true...

    this.getUserProfiles();

    var sessionsRef = db.collection("rewireSessions").doc(sessionID);

    unsubscribeEvent = sessionsRef.onSnapshot({ includeMetadataChanges: true }, doc => {
      if (doc.exists) {
        let docData = doc.data();
        let docID = doc.id;
        let teamData = docData.teams || [];

        let sessionActivities = docData.activities || null;

        var sessionPrice = docData.price;
        var formattedPrice = null;
        if (sessionPrice !== "undefined") {
          formattedPrice = Math.round((sessionPrice * 100) / 100).toFixed(2);
        } else {
          formattedPrice = null;
        }

        var sessionMembers = docData.enrolledUsers;

        var startDateMoment = moment.unix(docData.startDate);
        var endDateMoment = moment.unix(docData.endDate);

        var niceStartDate = startDateMoment.format("L");
        var niceEndDate = endDateMoment.format("L");

        var todayMoment = moment().tz("America/New_York");

        var registrationEndMoment = moment.unix(docData.startDate).subtract(5, "days");
        var registrationHasEnded = todayMoment.isAfter(registrationEndMoment);

        var alreadyStarted = startDateMoment.isBefore(todayMoment);
        var alreadyEnded = endDateMoment.isBefore(todayMoment);
        var daysFromNow = Math.abs(startDateMoment.diff(todayMoment, "days"));
        var HoursFromNow = Math.abs(startDateMoment.diff(todayMoment, "hours"));
        var daysSinceEnd = endDateMoment.fromNow();

        var dateContext = "";
        if (!alreadyStarted && !alreadyEnded) {
          if (HoursFromNow <= 48) {
            dateContext = `Starts in ${HoursFromNow} hours(s)`;
          } else {
            dateContext = `Starts in ${daysFromNow} day(s)`;
          }
        } else if (alreadyStarted && !alreadyEnded) {
          if (daysFromNow === 0) {
            dateContext = `Started today. Ends ${daysSinceEnd}.`;
          } else {
            dateContext = `Started ${daysFromNow} day(s) ago. Ends ${daysSinceEnd}.`;
          }
        } else if (alreadyEnded) {
          dateContext = `Ended ${daysSinceEnd}`;
        }

        document.title = `${appConfig.app.siteTitle} - ${niceStartDate} Session Details`;
        this.setState({
          sessionID: docID,
          sessionActivities: sessionActivities,
          sessionPrice: formattedPrice,
          sessionPageHeading: niceStartDate,
          sessionNotFound: false,
          sessionMembers: sessionMembers,
          unixStartDate: docData.startDate,
          unixEndDate: docData.endDate,
          sessionStartDate: niceStartDate,
          sessionEndDate: niceEndDate,
          sessionOngoing: alreadyStarted,
          registrationOver: registrationHasEnded,
          sessionOver: alreadyEnded,
          dateContext: dateContext,
          teamData: [...teamData]
        });
        if (this.props.authorizedUser) {
          this.refreshUserHabits();
        } else {
          this.setState({ isLoading: false });
        }
        this.subscribeRankings();
        /* this.refreshRankings(); */
      } else {
        this.setState({ isLoading: false, sessionNotFound: true });
      }
    });
  };

  /* subscribeTeamData = () => {
    const { sessionID } = this.props.match.params;

    var sessionsRef = db.collection("rewireSessions").doc(sessionID);

    unsubscribeTeamData = sessionsRef
      .onSnapshot({ includeMetadataChanges: true }, doc => {
        if (doc.exists) {
          let docData = doc.data();
          let teamData = docData.teams || null;

          this.setState({
            teamData: [...teamData]
          });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false, error: error });
      });
  }; */

  enrollmentStatus = () => {
    const fourthStyle = { textAlign: "center", color: "#00a726" };
    const greenStyle = { color: "#00a726" };
    if (
      !this.state.isLoading &&
      !this.props.appLoading &&
      this.props.authorizedUser &&
      this.state.sessionStartDate &&
      this.state.sessionEndDate &&
      this.isEnrolled()
    ) {
      return (
        <h3 className="section-title notopmargin" style={fourthStyle}>
          <span>
            <Icon style={greenStyle} name="check circle" />
            You are enrolled in this session
          </span>
        </h3>
      );
    } else {
      return null;
    }
  };

  teamInfoButton = () => {
    const style = { textAlign: "center" };
    if (
      !this.state.isLoading &&
      !this.props.appLoading &&
      this.props.authorizedUser &&
      this.state.sessionStartDate &&
      this.state.sessionEndDate &&
      this.isEnrolled()
    ) {
      return (
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <div style={style}>
            <Button as={Link} to={`/session/${this.state.sessionID}/teams`} size="large" color="red" basic>
              {this.isPartOfTeam() && !this.isOwnerOfTeam() ? <Icon name="users" /> : ""}
              {this.isOwnerOfTeam() ? <Icon name="edit" /> : ""}
              {this.isPendingTeamAcceptance() && !this.isPartOfTeam() ? <Icon name="wait" /> : ""}
              {!this.isPartOfTeam() && !this.isPendingTeamAcceptance() ? <Icon name="users" /> : ""}

              {this.isPartOfTeam() ? this.isPartOfTeam().name : ""}
              {this.isPendingTeamAcceptance() ? "Team Request Pending" : ""}
              {!this.isPartOfTeam() && !this.isPendingTeamAcceptance() ? "Compete on a team" : ""}
            </Button>
          </div>
          {this.teamRequestsLabel()}
        </Grid.Column>
      );
    } else {
      return null;
    }
  };

  teamRequestsLabel = () => {
    const style = { textAlign: "center" };

    if (this.isOwnerOfTeam() && this.isOwnerOfTeam().pendingMembers.length) {
      return (
        <div style={style}>
          <Label as={Link} to={`/session/${this.state.sessionID}/teams`} basic color="red" pointing>
            <Label circular color="red">
              {this.isOwnerOfTeam().pendingMembers.length}
            </Label>
            &nbsp;&nbsp;Requests
          </Label>
        </div>
      );
    }
  };

  enrollButton = () => {
    const style = { textAlign: "center" };
    if (
      !this.pageLoading() &&
      this.props.authorizedUser &&
      !this.state.sessionOver &&
      !this.state.registrationOver &&
      !this.state.sessionOngoing &&
      !this.isEnrolled()
    ) {
      return (
        <div style={style}>
          <Button primary size={"huge"} color="red" as={Link} to={`/session/${this.state.sessionID}/payment`}>
            {this.state.sessionPrice ? `Enroll now for $${this.state.sessionPrice}` : `Enroll now`}
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  isEnrolled = () => {
    if (auth.currentUser != null && !this.props.appLoading) {
      if (this.state.sessionMembers.indexOf(auth.currentUser.uid) !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  canEnroll = () => {
    if (
      !this.pageLoading() &&
      this.props.authorizedUser &&
      !this.state.sessionOver &&
      !this.state.registrationOver &&
      !this.state.sessionOngoing &&
      !this.isEnrolled()
    ) {
      return true;
    } else {
      return false;
    }
  };

  isRegistrationOver = () => {
    if (!this.pageLoading() && this.state.registrationOver && !this.isEnrolled()) {
      return true;
    } else {
      return false;
    }
  };

  isPartOfTeam = () => {
    if (!this.state.isLoading && !this.props.appLoading && this.props.authorizedUser && !this.state.sessionOver) {
      for (let teamIndex in this.state.teamData) {
        for (let memberIndex in this.state.teamData[teamIndex].acceptedMembers) {
          if (this.state.teamData[teamIndex].acceptedMembers[memberIndex].authID === auth.currentUser.uid) {
            return this.state.teamData[teamIndex];
          }
        }
      }
      return false;
    } else {
      return false;
    }
  };

  isPendingTeamAcceptance = () => {
    if (!this.state.isLoading && !this.props.appLoading && this.props.authorizedUser && !this.state.sessionOver) {
      for (let teamIndex in this.state.teamData) {
        for (let memberIndex in this.state.teamData[teamIndex].pendingMembers) {
          if (this.state.teamData[teamIndex].pendingMembers[memberIndex].authID === auth.currentUser.uid) {
            return this.state.teamData[teamIndex];
          }
        }
      }
      return false;
    } else {
      return false;
    }
  };

  isOwnerOfTeam = () => {
    if (!this.state.isLoading && !this.props.appLoading && this.props.authorizedUser && !this.state.sessionOver) {
      for (let teamIndex in this.state.teamData) {
        if (this.state.teamData[teamIndex].owner === auth.currentUser.uid) {
          return this.state.teamData[teamIndex];
        }
      }
      return false;
    } else {
      return false;
    }
  };

  getTeamByOwnerID = teamOwnerID => {
    if (!this.state.isLoading && !this.props.appLoading && this.props.authorizedUser && !this.state.sessionOver) {
      for (let teamIndex in this.state.teamData) {
        if (this.state.teamData[teamIndex].owner === teamOwnerID) {
          return this.state.teamData[teamIndex];
        }
      }
      return false;
    } else {
      return false;
    }
  };

  comingSoonMessage = () => {
    return (
      <h3 className="center-text">
        Please watch your e-mail for any news and come back on {this.state.sessionStartDate} to start your Routine Rewire!
      </h3>
    );
  };

  registrationEndedMessage = () => {
    return <h3 className="center-text">Registration has ended for this session</h3>;
  };

  sectionOverview = () => {
    return (
      <ContentContainer>
        <div className="session-overview">
          <Grid container>
            <Grid.Row centered>
              <Form autoComplete="off" loading={this.pageLoading()} size={"large"} className="fullwidth minHeight">
                <Grid columns={1} centered>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <SessionOverviewHeading
                      startDate={this.state.sessionStartDate}
                      endDate={this.state.sessionEndDate}
                      dateContext={this.state.dateContext}
                    />
                    {this.enrollmentStatus()}
                  </Grid.Column>
                  {/* this.teamInfoButton() */}
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    {!this.props.authorizedUser && !this.isRegistrationOver() && (
                      <SessionVisitorLogin isLoading={this.pageLoading()} />
                    )}
                    {this.canEnroll() && this.enrollButton()}
                    {this.isEnrolled() && this.comingSoonMessage()}
                    {this.isRegistrationOver() && this.registrationEndedMessage()}
                  </Grid.Column>
                </Grid>
              </Form>
            </Grid.Row>
          </Grid>
        </div>
      </ContentContainer>
    );
  };

  sectionTeamsPreview = () => {
    return (
      <ContentContainer>
        <div className="session-teams">
          <Grid container>
            <Grid.Row centered>
              <Form autoComplete="off" loading={this.pageLoading()} size={"large"} className="fullwidth minHeight">
                <Grid columns={1} centered>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <h1 className="section-title nomargins center-text">
                      <span>Teams</span>
                    </h1>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <div className="center-text">{this.isEnrolled() && this.comingSoonMessage()}</div>
                  </Grid.Column>
                </Grid>
              </Form>
            </Grid.Row>
          </Grid>
        </div>
      </ContentContainer>
    );
  };

  createTeam = () => {
    const teamName = this.fieldTeamCreate.current.inputRef.value.trim();
    var createNewTeam = functions.httpsCallable("createTeam");
    this.setState({ isLoading: true, error: null });

    createNewTeam({ sessionID: this.state.sessionID, teamName: teamName })
      .then(result => {
        //this.setState({ isLoading: false });
        //console.log(result);
        /* this.refreshTeamData(); */
        /* this.refreshRankings(); */
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          error: { createTeam: error.message }
        });
      });
  };

  teamCreateSectionHeading = () => {
    const primaryColor = { color: "#118689" };
    const style = {
      fontWeight: "bold",
      color: "#ACACAC",
      textAlign: "center"
    };

    if (this.isPartOfTeam() || this.isPendingTeamAcceptance()) {
      return (
        <div className="center-text">
          {/* this.isEnrolled() && this.comingSoonMessage() */}
          <h2 style={primaryColor}>Create a team</h2>
          <p className="justify-text">
            You cannot create a team while you are pending acceptance or are an existing member of a team. You must leave your
            current team or cancel your join request before you can create your own team.
          </p>
        </div>
      );
    }

    if (this.isOwnerOfTeam()) {
      return (
        <div className="center-text">
          {/* this.isEnrolled() && this.comingSoonMessage() */}
          <h2 style={primaryColor} className="nomargins">
            Manage Your Team
          </h2>
          <h3 style={style} className="nomargins extrapaddingbottom1">
            {this.isOwnerOfTeam().name}
          </h3>
        </div>
      );
    } else {
      return (
        <div className="center-text">
          {/* this.isEnrolled() && this.comingSoonMessage() */}
          <h2 style={primaryColor}>Create a team</h2>
          <p className="justify-text">
            After you create a team, other session participants will be able to join your team. Any requests to join your team
            must be accepted by the end of the first day before the team selection deadline. You cannot rename a team without
            deleting it (any existing members would have to re-join).
          </p>
        </div>
      );
    }
  };

  teamJoinSectionHeading = () => {
    const primaryColor = { color: "#118689" };
    const style = {
      fontWeight: "bold",
      color: "#ACACAC",
      textAlign: "center"
    };
    const redStyle = { color: "#FF5246" };

    const canJoinATeam = (
      <p className="justify-text">
        If you request to join a team, the team owner must accept your request by the team selection deadline otherwise it will be
        automatically rejected. If your request is rejected or you don't meet the deadline, you will compete only as an
        individual. You can leave or change teams at will before the deadline.
      </p>
    );

    const cannotJoinATeam = (
      <p className="justify-text">
        You cannot join someone else's team while you manage a team. You must delete your own team before you can join another.
      </p>
    );

    const pendingAcceptance = (
      <p className="justify-text">
        You currently have a pending request to join a team. The team owner must accept your request before the team selection
        deadline or you will only be competing at the individual level.
      </p>
    );

    const isTeamMember = (
      <p className="justify-text">
        You are currently a member of <strong>{this.isPartOfTeam().name}</strong>. Your scores will contribute to the overall
        placement of your team in the rankings.
      </p>
    );

    if (this.isPartOfTeam()) {
      return (
        <div className="center-text">
          {/* this.isEnrolled() && this.comingSoonMessage() */}
          <h2 style={primaryColor} className="nomargins">
            Team membership
          </h2>
          <h3 style={style} className="nomargins extrapaddingbottom1">
            {this.isPartOfTeam().name}
          </h3>
          {isTeamMember}
        </div>
      );
    } else if (this.isPendingTeamAcceptance()) {
      return (
        <div className="center-text">
          {/* this.isEnrolled() && this.comingSoonMessage() */}
          <h2 style={primaryColor} className="nomargins">
            Team membership
          </h2>
          <h3 style={style} className="nomargins">
            {this.isPendingTeamAcceptance().name}
          </h3>
          <h4 style={redStyle} className="nomargins extrapaddingbottom1">
            PENDING ACCEPTANCE
          </h4>
          {pendingAcceptance}
        </div>
      );
    } else {
      return (
        <div className="center-text">
          {/* this.isEnrolled() && this.comingSoonMessage() */}
          <h2 style={primaryColor}>Join a team</h2>
          {!this.isOwnerOfTeam() && !this.isPartOfTeam() ? canJoinATeam : null}
          {this.isOwnerOfTeam() ? cannotJoinATeam : null}
        </div>
      );
    }
  };

  deleteTeam = () => {
    this.setState({ modalShown: null, isLoading: true });
    var deleteTeam = functions.httpsCallable("deleteTeam");
    deleteTeam({ sessionID: this.state.sessionID })
      .then(result => {
        //this.setState({ isLoading: false });
        //console.log(result);
        /* this.refreshTeamData(); */
        /* this.refreshRankings(); */
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          error: { deleteTeam: error.message }
        });
      });
  };

  joinTeam = () => {
    const teamSelection = this.fieldTeamSelect.current.value.trim();
    if (teamSelection === "none") {
      return;
    }
    var submitJoinRequest = functions.httpsCallable("requestTeamMembership");
    this.setState({ isLoading: true, error: null });

    submitJoinRequest({
      teamOwnerID: teamSelection,
      sessionID: this.state.sessionID
    })
      .then(result => {
        /* this.refreshTeamData(); */
      })
      .catch(error => {
        this.setState({ isLoading: false, error: { joinTeam: error.message } });
      });
  };

  removeTeamJoinRequest = () => {
    this.setState({ modalShown: null, isLoading: true });
    var removeRequest = functions.httpsCallable("removeTeamJoinRequest");
    removeRequest({
      sessionID: this.state.sessionID,
      teamOwnerID: this.isPendingTeamAcceptance().owner
    })
      .then(result => {
        //this.setState({ isLoading: false });
        //console.log(result);
        /* this.refreshTeamData(); */
      })
      .catch(error => {
        this.setState({ isLoading: false, error: { joinTeam: error.message } });
      });
  };

  leaveCurrentTeam = () => {
    this.setState({ modalShown: null, isLoading: true });
    var leaveTeam = functions.httpsCallable("leaveCurrentTeam");
    leaveTeam({
      sessionID: this.state.sessionID,
      teamOwnerID: this.isPartOfTeam().owner
    })
      .then(result => {
        //this.setState({ isLoading: false });
        //console.log(result);
        /* this.refreshTeamData(); */
      })
      .catch(error => {
        this.setState({ isLoading: false, error: { joinTeam: error.message } });
      });
  };

  teamInfoTable = () => {
    var teamSelection = null;
    if (!this.pageLoading() && this.fieldTeamSelect.current) {
      teamSelection = this.fieldTeamSelect.current.value.trim();
    }

    if (this.getTeamByOwnerID(teamSelection)) {
      var sortFn = (a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      };

      const noTeamMembers = (
        <List.Item key="empty">
          <List.Content className="center-text extrapaddingbottomp5 extrapaddingtopp5">
            <p>
              There are no members on <strong>{this.getTeamByOwnerID(teamSelection).name}</strong> at this time
            </p>
          </List.Content>
        </List.Item>
      );

      var teamOwnerID = this.getTeamByOwnerID(teamSelection).owner;
      const currentTeamOwner = this.getUserProfileByAuthID(teamOwnerID).map((member, index) => {
        return (
          <List.Item key={member.firstName + " " + member.lastName}>
            <List.Content className="center-text">
              <p>{member.firstName + " " + member.lastName} (Team Captain)</p>
            </List.Content>
          </List.Item>
        );
      });

      var acceptedMemberList = this.getTeamByOwnerID(teamSelection)
        .acceptedMembers.sort(sortFn)
        .map((member, index) => {
          return (
            <List.Item key={member.name}>
              <List.Content className="center-text">
                <p>{member.name}</p>
              </List.Content>
            </List.Item>
          );
        });

      if (currentTeamOwner) {
        acceptedMemberList = [currentTeamOwner, ...acceptedMemberList];
      }

      return (
        <Segment>
          <Label attached="top">{`${this.getTeamByOwnerID(teamSelection).name} Members`}</Label>
          <List divided verticalAlign={"middle"}>
            {acceptedMemberList.length ? acceptedMemberList : noTeamMembers}
          </List>
        </Segment>
      );
    }
  };

  showTeamInfoTable = () => {
    if (!this.pageLoading()) {
      const teamSelection = this.fieldTeamSelect.current.value.trim();
      if (teamSelection !== "none") {
        this.setState({ showTeamMembersTable: true });
      } else {
        this.setState({ showTeamMembersTable: false });
      }
    }
  };

  teamJoinControls = deadlinePassed => {
    const style = { paddingTop: "10px" };
    const style2 = { paddingTop: "20px" };

    var sortFn = (a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    };

    if (!this.pageLoading()) {
      let teamOptions = [];
      if (this.state.teamData.length) {
        teamOptions = this.state.teamData.sort(sortFn).map(team => {
          return (
            <option key={team.owner} value={team.owner}>
              {team.name}
            </option>
          );
        });
        teamOptions.unshift(
          <option key="none" value="none">
            -- Select a team ---
          </option>
        );
        if (this.isPartOfTeam()) {
          //there should be an owner array like acceptedMembers with the authID and Name TODO
          const currentTeamOwner = this.getUserProfileByAuthID(this.isPartOfTeam().owner).map((member, index) => {
            return (
              <List.Item key={member.firstName + " " + member.lastName}>
                <List.Content>{member.firstName + " " + member.lastName} (Team Captain)</List.Content>
              </List.Item>
            );
          });

          var currentMembersList = this.isPartOfTeam()
            .acceptedMembers.sort(sortFn)
            .map((member, index) => {
              return (
                <List.Item key={member.name}>
                  <List.Content>{member.name}</List.Content>
                </List.Item>
              );
            });

          if (currentTeamOwner) {
            currentMembersList = [currentTeamOwner, ...currentMembersList];
          }

          const currentMembers = (
            <Segment>
              <Label attached="top">{this.isPartOfTeam().name} Roster</Label>
              <List divided verticalAlign={"middle"}>
                {currentMembersList}
              </List>
            </Segment>
          );

          return (
            <div style={style2}>
              {currentMembers}
              <div className="center-text">
                <Button
                  basic
                  size={"large"}
                  color={"red"}
                  disabled={this.state.isLoading || deadlinePassed}
                  onClick={() => this.setState({ modalShown: "leave-team" })}
                >
                  <Icon name={"user cancel"} />
                  Leave Team
                </Button>

                <Modal basic size="small" open={this.state.modalShown === "leave-team"}>
                  <Header icon="user cancel" content="Leave your current team?" />
                  <Modal.Content>
                    <h3>
                      If you leave your current team, you will be competing only at the individual level. If you wish to re-join
                      or join a different team, the team owner must accept your request prior to the team selection deadline.
                    </h3>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button basic color="red" inverted onClick={() => this.setState({ modalShown: null })}>
                      <Icon name="remove" /> Cancel
                    </Button>
                    <Button color="green" inverted onClick={this.leaveCurrentTeam}>
                      <Icon name="user cancel" /> Leave Team
                    </Button>
                  </Modal.Actions>
                </Modal>
              </div>
            </div>
          );
        } else if (this.isPendingTeamAcceptance()) {
          //show cancel request button
          return (
            <div className="center-text" style={style2}>
              <Button
                basic
                size={"large"}
                color={"red"}
                disabled={this.state.isLoading || deadlinePassed}
                onClick={() => this.setState({ modalShown: "cancel-team" })}
              >
                <Icon name={"user cancel"} />
                Delete Request
              </Button>

              <Modal basic size="small" open={this.state.modalShown === "cancel-team"}>
                <Header icon="user cancel" content="Delete your team membership request?" />
                <Modal.Content>
                  <h3>
                    If you delete your team membership request, the team owner will not be able to add you to their team unless
                    you re-request to join.
                  </h3>
                </Modal.Content>
                <Modal.Actions>
                  <Button basic color="red" inverted onClick={() => this.setState({ modalShown: null })}>
                    <Icon name="remove" /> Cancel
                  </Button>
                  <Button color="green" inverted onClick={this.removeTeamJoinRequest}>
                    <Icon name="user cancel" /> Delete Request
                  </Button>
                </Modal.Actions>
              </Modal>
            </div>
          );
        } else {
          return (
            <div style={style}>
              <Form.Field error={this.state.fieldErrors.fieldTeamSelect ? true : false}>
                <label>Team Selection</label>
                <Button as={"div"} labelPosition={"left"} fluid>
                  <select
                    name="fieldTeamSelect"
                    placeholder="Select a team"
                    autoComplete="off"
                    disabled={this.state.isLoading || deadlinePassed}
                    ref={this.fieldTeamSelect}
                    options={teamOptions}
                    onChange={this.showTeamInfoTable}
                  >
                    {teamOptions}
                  </select>
                  <Button
                    primary
                    content="Join"
                    floated={"right"}
                    disabled={this.state.isLoading || deadlinePassed}
                    onClick={this.joinTeam}
                  />
                </Button>

                <small>Select a team to join</small>
                {this.state.showTeamMembersTable && this.teamInfoTable()}
              </Form.Field>
            </div>
          );
        }
      } else {
        teamOptions = (
          <option key="no-teams-available" value="no-teams-available">
            No available teams at this time
          </option>
        );
        return (
          <div style={style}>
            <Form.Field error={this.state.fieldErrors.fieldTeamSelect ? true : false}>
              <label>Team Selection</label>
              <Button as={"div"} labelPosition={"left"} fluid>
                <select
                  name="fieldTeamSelect"
                  placeholder="Select a team"
                  autoComplete="off"
                  ref={this.fieldTeamSelect}
                  disabled={deadlinePassed}
                  options={teamOptions}
                >
                  {teamOptions}
                </select>
                <Button primary content="Join" floated={"right"} disabled={deadlinePassed} />
              </Button>

              <small>Select a team to join</small>
            </Form.Field>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  declineTeamJoinRequest = authID => {
    //console.log("DECLINE " + authID);
    var declineRequest = functions.httpsCallable("declineTeamJoinRequest");
    this.setState({ isLoading: true, error: null });

    declineRequest({ declinedUserID: authID, sessionID: this.state.sessionID })
      .then(result => {
        /* this.refreshTeamData(); */
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          error: { createTeam: error.message }
        });
      });
  };

  acceptTeamJoinRequest = authID => {
    //console.log("ACCEPT " + authID);
    var acceptRequest = functions.httpsCallable("acceptTeamJoinRequest");
    this.setState({ isLoading: true, error: null });

    acceptRequest({ acceptedUserID: authID, sessionID: this.state.sessionID })
      .then(result => {
        /* this.refreshTeamData(); */
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          error: { createTeam: error.message }
        });
      });
  };

  teamCreateControls = deadlinePassed => {
    const style = { paddingTop: "10px" };

    var sortFn = (a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    };

    if (this.isPartOfTeam() || this.isPendingTeamAcceptance()) {
      return null;
    }

    if (this.isOwnerOfTeam()) {
      const pendingMembersList = this.isOwnerOfTeam()
        .pendingMembers.sort(sortFn)
        .map((member, index) => {
          return (
            <List.Item key={index}>
              <List.Content className="center-text extrapaddingbottomp5 extrapaddingtopp5">
                <h3>{member.name}</h3>
              </List.Content>
              <List.Content className="center-text extrapaddingbottom1">
                <Button.Group>
                  <Button
                    primary
                    icon
                    disabled={this.state.isLoading || deadlinePassed}
                    onClick={() => this.acceptTeamJoinRequest(member.authID)}
                  >
                    <Icon name={"check"} />
                    Yes&nbsp;&nbsp;
                  </Button>
                  <Button.Or />
                  <Button
                    secondary
                    icon
                    disabled={this.state.isLoading || deadlinePassed}
                    onClick={() => this.declineTeamJoinRequest(member.authID)}
                  >
                    &nbsp;
                    <Icon name={"close"} />
                    No
                  </Button>
                </Button.Group>
              </List.Content>
            </List.Item>
          );
        });
      var teamOwnerID = this.isOwnerOfTeam().owner;
      const currentTeamOwner = this.getUserProfileByAuthID(teamOwnerID).map((member, index) => {
        //console.log(member);
        return (
          <List.Item key={member.firstName + " " + member.lastName}>
            <List.Content>{member.firstName + " " + member.lastName} (Team Captain) -- <strong>{this.getIndividualStatsPoints(member.authID)} pts.</strong></List.Content>
          </List.Item>
        );
      });

      var currentMembersList = this.isOwnerOfTeam()
        .acceptedMembers.sort(sortFn)
        .map((member, index) => {
          return (
            <List.Item key={member.name}>
              <List.Content>{member.name} -- <strong>{this.getIndividualStatsPoints(member.authID)} pts.</strong></List.Content>
            </List.Item>
          );
        });

      if (currentTeamOwner) {
        currentMembersList = [currentTeamOwner, ...currentMembersList];
      }

      const currentMembers = (
        <Segment>
          <Label attached="top">Team Roster</Label>
          <List divided verticalAlign={"middle"}>
            {currentMembersList}
          </List>
        </Segment>
      );

      const pendingMembers = (
        <Segment>
          <Label attached="top">
            Membership Requests
            {this.isOwnerOfTeam().pendingMembers && this.isOwnerOfTeam().pendingMembers.length > 0 ? (
              <Label size={"mini"} className="labelmargin" circular color={"red"}>
                {this.isOwnerOfTeam().pendingMembers.length}
              </Label>
            ) : null}
          </Label>
          <List divided verticalAlign={"middle"}>
            {pendingMembersList}
          </List>
        </Segment>
      );

      /* const noCurrentMembers = (
        <Segment padded>
          <Label attached="top">Team Roster</Label>
          <p className="center-text">No one has joined your team yet.</p>
        </Segment>
      ); */

      const noPendingMembers = (
        <Segment padded>
          <Label attached="top">Membership Requests</Label>
          <p className="center-text">There are no pending requests to join your team at the moment.</p>
        </Segment>
      );

      return (
        <div>
          {this.isOwnerOfTeam().pendingMembers.length ? !deadlinePassed && pendingMembers : !deadlinePassed && noPendingMembers}

          {currentMembers}
          <div className="center-text">
            {this.showCurrentErrors("deleteTeam")}
            <Button
              basic
              size={"large"}
              color={"red"}
              disabled={this.state.isLoading || deadlinePassed}
              onClick={() => this.setState({ modalShown: "delete-team" })}
            >
              <Icon name={"trash alternate outline"} />
              Delete Team
            </Button>

            <Modal basic size="small" open={this.state.modalShown === "delete-team"}>
              <Header icon="trash alternate outline" content="Delete your team?" />
              <Modal.Content>
                <h3>
                  If you delete your team, all members on your roster will be competing as individuals unless they join another
                  team.
                </h3>
              </Modal.Content>
              <Modal.Actions>
                <Button basic color="red" inverted onClick={() => this.setState({ modalShown: null })}>
                  <Icon name="remove" /> Cancel
                </Button>
                <Button color="green" inverted onClick={this.deleteTeam}>
                  <Icon name="trash alternate outline" /> Delete
                </Button>
              </Modal.Actions>
            </Modal>
          </div>
        </div>
      );
    } else {
      return (
        <div style={style}>
          <Form.Field error={this.state.fieldErrors.fieldTeamCreate ? true : false}>
            <label>Team Name</label>
            <Input
              fluid
              name="fieldTeamCreate"
              type="text"
              placeholder="Team Name"
              disabled={this.state.isLoading || deadlinePassed}
              maxLength="30"
              autoComplete="off"
              action
              ref={this.fieldTeamCreate}
            >
              <input />
              <Button primary content="Create" disabled={this.state.isLoading || deadlinePassed} onClick={this.createTeam} />
            </Input>
            <small>Publicly-visible team name</small>
          </Form.Field>
        </div>
      );
    }
  };

  sectionTeams = () => {
    const style = {
      fontWeight: "bold",
      color: "#ACACAC",
      textAlign: "center"
    };

    const todayMoment = moment().tz("America/New_York");
    const startDateMoment = moment.unix(this.state.unixStartDate);
    const startDatePlusOne = startDateMoment.add(24, "hours");
    const teamDeadlineString = "Team selection ends in " + startDatePlusOne.fromNow(true);
    const alreadyPassedString = "Team selection has already ended";

    const deadlinePassed = startDatePlusOne.isBefore(todayMoment);
    let deadlineString = "";
    if (deadlinePassed === false) {
      deadlineString = teamDeadlineString;
    } else {
      deadlineString = alreadyPassedString;
    }

    return (
      <PageVisibility onChange={this.handleTeamVisibilityChange}>
        <ContentContainer>
          <div className="session-teams">
            <Grid container>
              <Grid.Row centered>
                <Form
                  autoComplete="off"
                  loading={this.pageLoading()}
                  disabled={deadlinePassed}
                  size={"large"}
                  className="fullwidth minHeight"
                  error={this.state.error ? true : false}
                >
                  <Grid columns={1} centered>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <h1 className="section-title nomargins center-text">
                        <span>Routine Rewire Teams</span>
                      </h1>

                      {this.pageLoading() ? (
                        ""
                      ) : (
                        <h3 style={style} className="nomargins">
                          {deadlineString}
                        </h3>
                      )}
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={14} computer={7} className="notopbottompadding">
                      <div className="center-text">
                        {/* this.isEnrolled() && this.comingSoonMessage() */}
                        <p className="justify-text">
                          Creating or joining a team is a great way for a group of people to compete collectively against other
                          teams.
                        </p>
                        <p className="justify-text">
                          Everyone participating in this Routine Rewire session is competing on an individual level. Being part of
                          a team is completely optional.
                        </p>
                      </div>
                    </Grid.Column>
                  </Grid>

                  <Grid columns={1} centered>
                    <Grid.Column mobile={16} tablet={8} computer={6} className="extrapaddingtop">
                      <Segment>
                        {this.teamCreateSectionHeading()}
                        {this.showCurrentErrors("createTeam")}
                        {this.teamCreateControls(deadlinePassed)}
                      </Segment>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={6} className="extrapaddingtop">
                      <Segment>
                        <div>
                          {/* this.isEnrolled() && this.comingSoonMessage() */}
                          {this.teamJoinSectionHeading()}
                          {this.showCurrentErrors("joinTeam")}
                          {!this.isOwnerOfTeam() ? this.teamJoinControls(deadlinePassed) : null}
                        </div>
                      </Segment>
                    </Grid.Column>
                  </Grid>
                </Form>
              </Grid.Row>
            </Grid>
          </div>
        </ContentContainer>
      </PageVisibility>
    );
  };

  getHabitsForDate = manualEpoc => {
    //const todayEpoc = manualEpoc || moment().startOf('day').unix();
    const todayEpoc = manualEpoc || parseInt(this.state.viewingHabitDate);
    if (!this.state.isLoading && !this.props.appLoading && this.props.authorizedUser) {
      for (let habitIndex in this.state.sessionActivities) {
        if (this.state.sessionActivities[habitIndex].date === todayEpoc) {
          return this.state.sessionActivities[habitIndex];
        }
      }
      return false;
    } else {
      return false;
    }
  };

  viewPreviousDayHabits = () => {
    var currentViewDate = moment.unix(parseInt(this.state.viewingHabitDate));
    var yesterdayViewDate = currentViewDate
      .subtract(1, "days")
      .startOf("day")
      .unix();
    this.setState({ viewingHabitDate: yesterdayViewDate });
  };

  viewNextDayHabits = () => {
    var currentViewDate = moment.unix(parseInt(this.state.viewingHabitDate));
    var nextDayViewDate = currentViewDate
      .add(1, "days")
      .startOf("day")
      .unix();
    this.setState({ viewingHabitDate: nextDayViewDate });
  };

  viewYesterdayButton = () => {
    var shouldShowButton = true;
    const { epocDate } = this.props.match.params;
    var currentDay = null;
    if (epocDate !== "undefined" && epocDate !== undefined) {
      currentDay = moment.unix(parseInt(epocDate));
    } else {
      currentDay = moment()
        .tz("America/New_York")
        .startOf("day");
    }

    var proposedPreviousDay = currentDay.subtract(1, "days");
    var eventStartDate = moment.unix(this.state.unixStartDate);
    var eventEndDate = moment.unix(this.state.unixEndDate);
    var currentViewingMoment = moment.unix(parseInt(this.state.viewingHabitDate));

    if (proposedPreviousDay.isBefore(eventStartDate)) {
      shouldShowButton = false;
    }

    if (currentViewingMoment.isSameOrBefore(proposedPreviousDay)) {
      shouldShowButton = false;
    }

    if (proposedPreviousDay.isAfter(eventEndDate)) {
      shouldShowButton = false;
    }

    if (shouldShowButton === true) {
      //show yesterday button
      return (
        <Button basic size={"large"} color={"red"} disabled={this.state.isLoading} onClick={() => this.viewPreviousDayHabits()}>
          <Icon name={"arrow alternate circle left outline"} />
          Yesterday's Habits
        </Button>
      );
    } else {
      return null;
    }
  };

  viewTodayButton = () => {
    var currentViewingMoment = moment.unix(parseInt(this.state.viewingHabitDate));
    const { epocDate } = this.props.match.params;
    var todayMoment = null;
    if (epocDate !== "undefined" && epocDate !== undefined) {
      todayMoment = moment.unix(parseInt(epocDate));
    } else {
      todayMoment = moment()
        .tz("America/New_York")
        .startOf("day");
    }
    var shouldShowTodayButton = currentViewingMoment.isBefore(todayMoment);
    if (shouldShowTodayButton === true) {
      //show today button
      return (
        <Button basic size={"large"} color={"red"} disabled={this.state.isLoading} onClick={() => this.viewNextDayHabits()}>
          Today's Habits
          <Icon name={"arrow alternate circle right outline"} />
        </Button>
      );
    } else {
      return null;
    }
  };

  viewYesterdayButtonTESTING = () => {
    return (
      <Button
        basic
        size={"large"}
        color={"red"}
        disabled={this.state.isLoading}
        className="extramarginbottom2"
        onClick={() => this.viewPreviousDayHabits()}
      >
        <Icon name={"arrow alternate circle left outline"} />
        Yesterday's Habits TESTING
      </Button>
    );
  };

  viewTodayButtonTESTING = () => {
    return (
      <Button
        basic
        size={"large"}
        color={"red"}
        disabled={this.state.isLoading}
        className="extramarginbottom2"
        onClick={() => this.viewNextDayHabits()}
      >
        Today's Habits TESTING
        <Icon name={"arrow alternate circle right outline"} />
      </Button>
    );
  };

  getUserProfileByAuthID = authID => {
    var foundProfile = null;
    foundProfile = this.state.allUserProfiles.filter(profile => profile.authID === authID);
    if (foundProfile.length) {
      return foundProfile;
    } else {
      return false;
    }
  };

  getUserProfiles = () => {
    var userProfiles = db.collection("userProfiles");

    unsubscribeProfiles = userProfiles.onSnapshot(
      {
        includeMetadataChanges: true
      },
      doc => {
        if (doc) {
          var userProfiles = [];
          doc.forEach(profileDoc => {
            let profileData = profileDoc.data();
            profileData.authID = profileDoc.id;
            //console.log(profileData);
            userProfiles.push(profileData);
            //allUserProfiles
          });
          this.setState({ allUserProfiles: [...userProfiles] });
        }
      }
    );
  };

  refreshUserHabits = shouldCheckDate => {
    const { sessionID } = this.props.match.params;
    const { epocDate } = this.props.match.params;
    if (shouldCheckDate === true) {
      var todayMoment = moment().tz("America/New_York");

      var startDateMoment = moment.unix(this.state.unixStartDate);
      var endDateMoment = moment.unix(this.state.unixEndDate);

      var alreadyStarted = startDateMoment.isBefore(todayMoment);
      var alreadyEnded = endDateMoment.isBefore(todayMoment);
      var daysFromNow = Math.abs(startDateMoment.diff(todayMoment, "days"));
      var HoursFromNow = Math.abs(startDateMoment.diff(todayMoment, "hours"));
      var daysSinceEnd = endDateMoment.fromNow();

      var dateContext = "";
      if (!alreadyStarted && !alreadyEnded) {
        if (HoursFromNow <= 48) {
          dateContext = `Starts in ${HoursFromNow} hours(s)`;
        } else {
          dateContext = `Starts in ${daysFromNow} day(s)`;
        }
      } else if (alreadyStarted && !alreadyEnded) {
        dateContext = `Started ${daysFromNow} day(s) ago. Ends ${daysSinceEnd}.`;
      } else if (alreadyEnded) {
        dateContext = `Ended ${daysSinceEnd}`;
      }

      if (epocDate !== "undefined" && epocDate !== undefined) {
        this.setState({ viewingHabitDate: epocDate });
      } else {
        this.setState({
          viewingHabitDate: moment()
            .tz("America/New_York")
            .startOf("day")
            .unix(),
          dateContext: dateContext
        });
      }
    }

    var userCompletedHabits = db
      .collection("rewireSessions")
      .doc(sessionID)
      .collection("scores")
      .doc(auth.currentUser.uid);

    userCompletedHabits
      .get()
      .then(doc => {
        if (doc.exists) {
          var docdata = doc.data();
          this.setState({ completedHabits: docdata.scores, isLoading: false });
        } else {
          //haven't saved any habits yet, so ignore
          this.setState({ completedHabits: {}, isLoading: false });
        }
      })
      .catch(error => {
        //TEST ERROR
        this.setState({
          isLoading: false,
          error: { viewHabits: error.message }
        });
      });
  };

  habitClick = itemKey => {
    //console.log("Clicked " + itemKey);
    if (this.state.userProfile === null) {
      const { history } = this.props;
      var userProfileRef = db.collection("userProfiles").doc(auth.currentUser.uid);
      userProfileRef.get().then(doc => {
        if (doc.exists) {
          var userProfileData = doc.data();
          var { displayName } = userProfileData;
          this.setState({ userProfile: doc.data() });
          const todayEpoc = moment.unix(parseInt(this.state.viewingHabitDate)).unix();
          var scoreSet = db
            .collection("rewireSessions")
            .doc(this.state.sessionID)
            .collection("scores")
            .doc(auth.currentUser.uid);
          scoreSet
            .set(
              {
                scores: {
                  [todayEpoc]: dbAdmin.FieldValue.arrayUnion(itemKey)
                },
                displayName: displayName
              },
              { merge: true }
            )
            .then(result => {
              this.refreshUserHabits();
              /* this.refreshRankings(); */
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          history.push("/account/profile");
        }
      });
    } else {
      //this.setState({ isLoading: true });
      //const todayEpoc = manualEpoc || moment().startOf('day').unix();
      var { displayName } = this.state.userProfile;
      const todayEpoc = moment.unix(parseInt(this.state.viewingHabitDate)).unix();
      var scoreSet = db
        .collection("rewireSessions")
        .doc(this.state.sessionID)
        .collection("scores")
        .doc(auth.currentUser.uid);
      scoreSet
        .set(
          {
            scores: {
              [todayEpoc]: dbAdmin.FieldValue.arrayUnion(itemKey)
            },
            displayName: displayName
          },
          { merge: true }
        )
        .then(result => {
          this.refreshUserHabits();
          /* this.refreshRankings(); */
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  habitsGrid = habitsObj => {
    if (!this.props.appLoading && !this.state.isLoading) {
      if (habitsObj) {
        var habitsDate = moment.unix(habitsObj.date);
        var niceStartDate = habitsDate.format("L");
        var remainingItems = 0;
        var remainingRoutines = 0;
        var remainingRewires = 0;
        var totalPossiblePoints = 0;
        var routineItems = habitsObj.routines.map(routineItem => {
          if (this.state.completedHabits) {
            if (
              this.state.completedHabits[habitsObj.date] &&
              this.state.completedHabits[habitsObj.date].includes(routineItem.key)
            ) {
              totalPossiblePoints++;
              return (
                <HabitTile
                  visible={false}
                  key={`${habitsObj.date}-${routineItem.key}`}
                  index={routineItem.key}
                  icon={routineItem.icon}
                  clickAction={this.habitClick}
                  text={routineItem.text}
                  category={"Routine"}
                />
              );
            } else {
              remainingItems++;
              remainingRoutines++;
              totalPossiblePoints++;
              return (
                <HabitTile
                  visible={true}
                  key={`${habitsObj.date}-${routineItem.key}`}
                  index={routineItem.key}
                  icon={routineItem.icon}
                  clickAction={this.habitClick}
                  text={routineItem.text}
                  category={"Routine"}
                />
              );
            }
          } else {
            totalPossiblePoints++;
            remainingItems++;
            remainingRoutines++;
            return (
              <HabitTile
                visible={true}
                key={`${habitsObj.date}-${routineItem.key}`}
                index={routineItem.key}
                icon={routineItem.icon}
                clickAction={this.habitClick}
                text={routineItem.text}
                category={"Routine"}
              />
            );
          }
        });
        var rewireItems = habitsObj.rewires.map(rewireItem => {
          if (this.state.completedHabits) {
            if (
              this.state.completedHabits[habitsObj.date] &&
              this.state.completedHabits[habitsObj.date].includes(rewireItem.key)
            ) {
              totalPossiblePoints++;
              return (
                <HabitTile
                  visible={false}
                  key={`${habitsObj.date}-${rewireItem.key}`}
                  index={rewireItem.key}
                  icon={rewireItem.icon}
                  clickAction={this.habitClick}
                  text={rewireItem.text}
                  category={"Rewire"}
                />
              );
            } else {
              totalPossiblePoints++;
              remainingRewires++;
              remainingItems++;
              return (
                <HabitTile
                  visible={true}
                  key={`${habitsObj.date}-${rewireItem.key}`}
                  index={rewireItem.key}
                  icon={rewireItem.icon}
                  clickAction={this.habitClick}
                  text={rewireItem.text}
                  category={"Rewire"}
                />
              );
            }
          } else {
            totalPossiblePoints++;
            remainingRewires++;
            remainingItems++;
            return (
              <HabitTile
                visible={true}
                key={`${habitsObj.date}-${rewireItem.key}`}
                index={rewireItem.key}
                icon={rewireItem.icon}
                clickAction={this.habitClick}
                text={rewireItem.text}
                category={"Rewire"}
              />
            );
          }
        });
        var everythingCompleted = (
          <Grid.Column mobile={16} tablet={16} computer={16} className="extramargintop2">
            <div className="center-text">
              <Icon name={"check circle outline"} color={"green"} size={"huge"} />
              <h2 className="topmarginp5 green-text nobottommargin">Nice work!</h2>
              <h3 className="green-text notopmargin">You completed all of the day's routines &amp; rewires!</h3>
            </div>
          </Grid.Column>
        );
        var routinesCompleted = (
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <div className="center-text">
              <Icon name={"check circle outline"} color={"green"} size={"huge"} />
              <h2 className="topmarginp5 green-text nobottommargin">Nice work!</h2>
              <h3 className="green-text notopmargin">You completed all of the day's routines!</h3>
            </div>
          </Grid.Column>
        );
        var rewiresCompleted = (
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <div className="center-text">
              <Icon name={"check circle outline"} color={"green"} size={"huge"} />
              <h2 className="topmarginp5 green-text nobottommargin">Nice work!</h2>
              <h3 className="green-text notopmargin">You completed all of the day's rewires!</h3>
            </div>
          </Grid.Column>
        );
        var habitDateHeading = (
          <h2 className="center-text extrapaddingtopp25 nobottommargin">
            <span className="primary-text">{niceStartDate}</span>
          </h2>
        );
        var routinesHeading = (
          <Grid.Column mobile={16} tablet={16} computer={16} className="">
            <h1 className="center-text underline extrapaddingtopp5">Routines</h1>
          </Grid.Column>
        );
        var rewiresHeading = (
          <Grid.Column mobile={16} tablet={16} computer={16} className="">
            <h1 className="center-text underline extrapaddingtopp5">Rewires</h1>
          </Grid.Column>
        );
        var possiblePoints = (
          <h4 className="center-text extrapaddingtopp25 extrapaddingbottomp5 notopmargin">
            You've earned {totalPossiblePoints - remainingItems}/{totalPossiblePoints} points for the day
          </h4>
        );
        return (
          <div>
            {habitDateHeading}
            {possiblePoints}
            {this.viewYesterdayButton()}
            {this.viewTodayButton()}
            <Grid columns={1} centered>
              {remainingItems === 0 ? everythingCompleted : null}
              {remainingItems !== 0 ? routinesHeading : null}
              {remainingItems !== 0 && remainingRoutines === 0 ? routinesCompleted : null}
              {routineItems}
              {remainingItems !== 0 ? rewiresHeading : null}
              {remainingItems !== 0 && remainingRewires === 0 ? rewiresCompleted : null}
              {rewireItems}
            </Grid>
          </div>
        );
      } else {
        //no activites for the given habitsObj
        var selectedDateMoment = moment.unix(parseInt(this.state.viewingHabitDate));
        var formattedDateMoment = selectedDateMoment.format("L");

        var eventStartMoment = moment.unix(this.state.unixStartDate);

        if (selectedDateMoment.isBefore(eventStartMoment)) {
          return (
            <div className="center-text extrapaddingtop">
              <h3>Return here on {this.state.sessionStartDate} to start your Routine Rewire!</h3>
            </div>
          );
        }

        return (
          <div>
            <h2 className="center-text extrapaddingtopp25">
              <span className="primary-text">{formattedDateMoment}</span>
            </h2>
            {this.viewYesterdayButton()}
            {this.viewTodayButton()}
            <div className="center-text extrapaddingtop">
              <h3>There are no assigned routines/rewires for {formattedDateMoment}</h3>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  sectionHabits = () => {
    const style = {
      fontWeight: "bold",
      color: "#ACACAC",
      textAlign: "center"
    };
    return (
      <PageVisibility onChange={this.handleHabitsVisibilityChange}>
        <ContentContainer>
          <div className="session-teams">
            <Grid container>
              <Grid.Row centered>
                <Form autoComplete="off" loading={this.pageLoading()} size={"large"} className="fullwidth minHeight">
                  <Grid columns={1} centered>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <h1 className="section-title nomargins center-text">
                        <span>Habits</span>
                      </h1>
                      <h3 style={style} className="nomargins">
                        Click/tap the icon to mark it completed
                      </h3>
                      {this.showCurrentErrors("viewHabits")}
                    </Grid.Column>
                  </Grid>
                  {/* <Grid.Column mobile={16} tablet={16} computer={16}>
                    <div className="center-text">{this.isEnrolled() && this.comingSoonMessage()}</div>
                  </Grid.Column> */}
                  {this.habitsGrid(this.getHabitsForDate())}
                </Form>
              </Grid.Row>
            </Grid>
          </div>
        </ContentContainer>
      </PageVisibility>
    );
  };

  subscribeRankings = () => {
    const { sessionID } = this.props.match.params;

    var userScores = db
      .collection("rewireSessions")
      .doc(sessionID)
      .collection("scores");

    unsubscribeRankings = userScores.onSnapshot(
      {
        includeMetadataChanges: true
      },
      doc => {
        if (doc) {
          var scoresArray = [];
          var teamScoresArray = [];
          var teamScoresObj = {};
          doc.forEach(scoreDoc => {
            var scoreObj = {};
            var totalScores = 0;
            var scoreData = scoreDoc.data();
            var userScores = scoreData.scores;
            var userDisplayname = scoreData.displayName;
            for (var key in userScores) {
              totalScores += userScores[key].length;
            }
            scoreObj.userID = scoreDoc.id;
            scoreObj.displayName = userDisplayname;
            scoreObj.points = totalScores;
            scoresArray.push(scoreObj);
            //console.log(scoreDoc.id);
            //console.log(scoreDoc.data());
          });
          //console.log(scoresArray);
          if (this.state.teamData.length) {
            for (let teamIndex in this.state.teamData) {
              /* if(this.state.teamData[teamIndex].owner === scoresArray[currentScore].userID && this.state.teamData[teamIndex].acceptedMembers.length > 0) { */
              let teamOwnerId = this.state.teamData[teamIndex].owner;
              let teamName = this.state.teamData[teamIndex].name;
              let teamSize = this.state.teamData[teamIndex].acceptedMembers.length;

              if (teamSize > 0) {
                if (teamScoresObj[teamOwnerId] === undefined) {
                  teamScoresObj[teamOwnerId] = {
                    points: 0,
                    displayName: teamName,
                    userID: teamOwnerId,
                    memberCount: 1
                  };
                }
              } else {
              }
            }
            for (let currentScore in scoresArray) {
              let scoreOwnerId = scoresArray[currentScore].userID;
              let currentScorePoints = scoresArray[currentScore].points;

              for (let teamIndex2 in this.state.teamData) {
                let teamOwnerId = this.state.teamData[teamIndex2].owner;

                if (teamScoresObj[teamOwnerId] !== undefined) {
                  for (let memberIndex in this.state.teamData[teamIndex2].acceptedMembers) {
                    let currentMember = this.state.teamData[teamIndex2].acceptedMembers[memberIndex];
                    if (currentMember.authID === scoreOwnerId) {
                      teamScoresObj[teamOwnerId].points += currentScorePoints;
                      teamScoresObj[teamOwnerId].memberCount++;
                    }
                  }
                }
              }
            }
            for (let teamOwnerId in teamScoresObj) {
              //console.log("YES");
              if (teamScoresObj[teamOwnerId].points > 0) {
                teamScoresArray.push({
                  displayName: teamScoresObj[teamOwnerId].displayName,
                  points: Math.round(teamScoresObj[teamOwnerId].points / teamScoresObj[teamOwnerId].memberCount)
                });
              }
            }
            this.setState({ sessionTeamScores: [...teamScoresArray] });
          }

          this.setState({ sessionIndividualScores: [...scoresArray] });
          //scores are now all stored in array

          //do loop stuff for teams
        }
      }
    );
    /* .catch(error => {
        //TEST ERROR
        this.setState({ error: { viewHabits: error.message } });
      }); */
  };

  toggleRankingsClick = () => {
    if (this.state.showScoresFor === "individuals") {
      this.setState({ showScoresFor: "teams" });
    } else if (this.state.showScoresFor === "teams") {
      this.setState({ showScoresFor: "individuals" });
    } else {
      this.setState({ showScoresFor: "individuals" });
    }
  };

  toggleRankingsButton = () => {
    if (this.state.showScoresFor === "individuals") {
      return (
        <Button basic size={"large"} color={"red"} disabled={this.state.isLoading} onClick={() => this.toggleRankingsClick()}>
          <Icon name={"users"} />
          View Team Scores
        </Button>
      );
    } else if (this.state.showScoresFor === "teams") {
      return (
        <Button basic size={"large"} color={"red"} disabled={this.state.isLoading} onClick={() => this.toggleRankingsClick()}>
          <Icon name={"user"} />
          View Individual Scores
        </Button>
      );
    } else {
      return null;
    }
  };

  displayRankingsTable = () => {
    var tableMarkup = (headingText, scoreRows) => {
      return (
        <div>
          <h3 className="center-text">{headingText}</h3>
          <Table celled compact striped unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Points</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{scoreRows}</Table.Body>
          </Table>
        </div>
      );
    };
    var sortFn = (a, b) => {
      if (a.points > b.points) return -1;
      if (a.points < b.points) return 1;
      if (a.points === b.points && a.displayName > b.displayName) return 1;
      if (a.points === b.points && a.displayName < b.displayName) return -1;
      return 0;
    };
    var category = this.state.showScoresFor;
    var baseScoreArray = null;
    var scoresTableRows = null;
    if (category === "individuals") {
      baseScoreArray = [...this.state.sessionIndividualScores];
      if (baseScoreArray.length) {
        scoresTableRows = baseScoreArray.sort(sortFn).map(scoreItem => {
          
          if(this.props.authorizedUser.uid === scoreItem.userID) {
            return (
              <Table.Row active key={scoreItem.userID}>
                <Table.Cell><strong>{scoreItem.displayName}</strong></Table.Cell>
                <Table.Cell><strong>{scoreItem.points}</strong></Table.Cell>
              </Table.Row>
            );
          } else {
            return (
              <Table.Row key={scoreItem.userID}>
                <Table.Cell>{scoreItem.displayName}</Table.Cell>
                <Table.Cell>{scoreItem.points}</Table.Cell>
              </Table.Row>
            );
          }

          
        });
        return tableMarkup("Individual Scores", scoresTableRows);
      } else {
        return <h3 className="center-text extrapaddingtop">There are currently no individual scores to display</h3>;
      }
    } else if (category === "teams") {
      baseScoreArray = [...this.state.sessionTeamScores];
      if (baseScoreArray.length) {
        scoresTableRows = baseScoreArray.sort(sortFn).map(scoreItem => {
          return (
            <Table.Row key={scoreItem.displayName}>
              <Table.Cell>{scoreItem.displayName}</Table.Cell>
              <Table.Cell>{scoreItem.points}</Table.Cell>
            </Table.Row>
          );
        });
        return tableMarkup("Team Scores", scoresTableRows);
      } else {
        return <h3 className="center-text extrapaddingtop">There are currently no team scores to display</h3>;
      }
    } else {
      return null;
    }
  };

  sectionRankings = () => {
    return (
      <PageVisibility onChange={this.handleRankingsVisibilityChange}>
        <ContentContainer>
          <div className="session-teams">
            <Grid container>
              <Grid.Row centered>
                <Form autoComplete="off" loading={this.pageLoading()} size={"large"} className="fullwidth minHeight">
                  <Grid columns={1} centered>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <h1 className="section-title center-text">
                        <span>Rankings</span>
                      </h1>
                      <div className="center-text">{this.toggleRankingsButton()}</div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={14} computer={14}>
                      <div className="center-text">{this.isEnrolled() && this.displayRankingsTable()}</div>
                    </Grid.Column>
                  </Grid>
                </Form>
              </Grid.Row>
            </Grid>
          </div>
        </ContentContainer>
      </PageVisibility>
    );
  };

  sectionStats = () => {
    return (
      <PageVisibility onChange={this.handleRankingsVisibilityChange}>
        <ContentContainer>
          <div className="session-teams">
            <Grid container>
              <Grid.Row centered>
                <Form autoComplete="off" loading={this.pageLoading()} size={"large"} className="fullwidth minHeight">
                  <Grid columns={1} centered>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <h1 className="section-title center-text">
                        <span>Stats</span>
                      </h1>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={14} computer={14} textAlign={"center"}>
                      {this.state.statsUserData === true ? this.statsTables() : this.statsGetUserDataButton()}
                    </Grid.Column>
                  </Grid>
                </Form>
              </Grid.Row>
            </Grid>
          </div>
        </ContentContainer>
      </PageVisibility>
    );
  };

  statsGetUserData = () => {
    this.setState({ statsUserData: false, isLoading: true, userProfiles: [] });

    var sessionActivities = {
      totalRewires: 0,
      totalRoutines: 0,
      dates: {},
      rewires: {},
      routines: {}
    };
    var userActivityStats = {
      totalRewires: 0,
      totalRoutines: 0,
      dates: {},
      rewires: {},
      routines: {}
    };
    this.state.sessionActivities.forEach(activity => {
      if (activity.rewires) {
        activity.rewires.forEach(action => {
          if (sessionActivities.dates[activity.date] === undefined) {
            sessionActivities.dates[activity.date] = 0;
          }
          if (sessionActivities.rewires[action.key] === undefined) {
            sessionActivities.rewires[action.key] = 0;
            userActivityStats.rewires[action.key] = 0;
          }
          sessionActivities.dates[activity.date]++;
          sessionActivities.rewires[action.key]++;
          sessionActivities.totalRewires++;
        });
      }
      if (activity.routines) {
        activity.routines.forEach(action => {
          if (sessionActivities.dates[activity.date] === undefined) {
            sessionActivities.dates[activity.date] = 0;
          }
          if (sessionActivities.routines[action.key] === undefined) {
            sessionActivities.routines[action.key] = 0;
            userActivityStats.routines[action.key] = 0;
          }
          sessionActivities.dates[activity.date]++;
          sessionActivities.routines[action.key]++;
          sessionActivities.totalRoutines++;
        });
      }
    });
    this.setState({ allActivities: sessionActivities });

    var userProfiles = [];
    var userScores = [];
    this.state.sessionMembers.forEach(userId => {
      userProfiles.push(
        db
          .collection("userProfiles")
          .doc(userId)
          .get()
      );

      userScores.push(
        db
          .collection("rewireSessions")
          .doc(this.state.sessionID)
          .collection("scores")
          .doc(userId)
          .get()
      );
    });
    var userProfilesData = [];
    Promise.all(userProfiles).then(result => {
      result.forEach(userProfile => {
        let profileObj = {
          userId: userProfile.id,
          ...userProfile.data(),
          completedRoutines: 0,
          completedRewires: 0,
          routines: {},
          rewires: {}
        };
        //console.log(profileObj);
        var targetProfile = this.state.sessionIndividualScores.filter(scoreData => {
          return scoreData.userID === userProfile.id;
        })
        if(targetProfile.length) {
          profileObj.points = targetProfile[0].points;
        }
        userProfilesData.push(profileObj);
      });
      Promise.all(userScores).then(result => {
        result.forEach(userScore => {
          //var userId = userScore.id;

          var userData = userScore.data();
          //console.log(userData);
          if (userData) {
            for(var key in userData.scores) {
              // eslint-disable-next-line
              userData.scores[key].forEach(scoreKey => {
                if (userActivityStats.dates[key] === undefined) {
                  userActivityStats.dates[key] = 0;
                }
                if (userActivityStats.routines[scoreKey] !== undefined) {
                  userActivityStats.routines[scoreKey]++;
                  userActivityStats.totalRoutines++;
                  userActivityStats.dates[key]++;
                }
                if (userActivityStats.rewires[scoreKey] !== undefined) {
                  userActivityStats.rewires[scoreKey]++;
                  userActivityStats.totalRewires++;
                  userActivityStats.dates[key]++;
                }
              });
            }
          }
        });
        //console.log(userActivityStats);
        this.setState({
          userProfiles: userProfilesData,
          scoreStats: userActivityStats,
          statsUserData: true,
          isLoading: false
        });
      });
    });
  };

  statsTables = () => {
    return (
      <>
      {this.statsUserTable()}
      <br/><br/>
      {this.statsRRTable("Routines")}
      <br/><br/>
      {this.statsRRTable("Rewires")}
      <br/><br/>
      {this.statsDatesTable()}
      </>
    )
  }

  statsDatesTable = () => {
    var tableMarkup = (heading, rowData) => {
      return (
        <div>
          <h3 className="center-text">{heading}</h3>
          <Table celled compact striped unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Completed (#)</Table.HeaderCell>
                <Table.HeaderCell>Completed (%)</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{rowData}</Table.Body>
          </Table>
        </div>
      );
    };
    if (this.state.scoreStats.dates) {
      var finalArray = [];
      for(var date in this.state.scoreStats.dates) {
        finalArray.push({key: date, data: this.state.scoreStats.dates[date]})
      }
      var rows = finalArray.map(item => {
        return (
          <Table.Row key={item.key}>
            <Table.Cell>{moment.unix(item.key).format("M/D/YYYY")}</Table.Cell>
            <Table.Cell>{item.data} / {this.state.allActivities.dates[item.key] * this.state.userProfiles.length}</Table.Cell>
            <Table.Cell>{((item.data/(this.state.allActivities.dates[item.key] * this.state.userProfiles.length))*100).toFixed(2)}%</Table.Cell>
          </Table.Row>
        );
      });
      return tableMarkup("Completion by date", rows);
    } else {
      return <h3 className="center-text extrapaddingtop">There are currently no date breakdowns to display</h3>;
    }
  }

  getIndividualStatsPoints = (uid) => {
    var foundScoreObj = this.state.sessionIndividualScores.filter(scoreObj => scoreObj.userID === uid);
    if(foundScoreObj.length === 1) {
      //console.log(foundScoreObj);
      return foundScoreObj[0].points;
    } else {
      //console.log("DIDNT FIND IT");
      return 0;
    }
  }

  statsRRTable = (category) => {
    var dataSource = null;
    var totalNum = null;
    var outOf = null;
    var heading = null;
    var refSource = null;
    if(category === "Routines") {
      dataSource = this.state.scoreStats.routines;
      totalNum = this.state.scoreStats.totalRoutines;
      outOf = this.state.allActivities.totalRoutines * this.state.userProfiles.length;
      refSource = this.state.allActivities.routines;
      heading = "Routine";
    } else if(category === "Rewires") {
      dataSource = this.state.scoreStats.rewires;
      totalNum = this.state.scoreStats.totalRewires;
      outOf = this.state.allActivities.totalRewires * this.state.userProfiles.length;
      refSource = this.state.allActivities.rewires;
      heading = "Rewire";
    }
    var tableMarkup = (category, totalNum, outOf, heading, rowData) => {
      return (
        <div>
          <h3 className="center-text">{category} ({totalNum}/{outOf})</h3>
          <Table celled compact striped unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{heading}</Table.HeaderCell>
                <Table.HeaderCell>Completed (#)</Table.HeaderCell>
                <Table.HeaderCell>Completed (%)</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{rowData}</Table.Body>
          </Table>
        </div>
      );
    };
    if (dataSource) {
      var finalArray = [];
      for(var key in dataSource) {
        finalArray.push({key: key, data: dataSource[key]})
      }
      var rows = finalArray.map(item => {
        return (
          <Table.Row key={item.key}>
            <Table.Cell>{item.key}</Table.Cell>
            <Table.Cell>{item.data} / {refSource[item.key] * this.state.userProfiles.length}</Table.Cell>
            <Table.Cell>{((item.data/(refSource[item.key] * this.state.userProfiles.length))*100).toFixed(2)}%</Table.Cell>
          </Table.Row>
        );
      });
      return tableMarkup(category, totalNum, outOf, heading, rows);
    } else {
      return <h3 className="center-text extrapaddingtop">There are currently no {category} to display</h3>;
    }
  }

  statsUserTable = () => {
    var tableMarkup = (headingText, rowData) => {
      return (
        <div>
          <h3 className="center-text">{headingText}</h3>
          <Table celled compact striped unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>DisplayName</Table.HeaderCell>
                <Table.HeaderCell>UserID</Table.HeaderCell>
                <Table.HeaderCell>FirstName</Table.HeaderCell>
                <Table.HeaderCell>LastName</Table.HeaderCell>
                <Table.HeaderCell>PhoneNumber</Table.HeaderCell>
                <Table.HeaderCell>ZipCode</Table.HeaderCell>
                <Table.HeaderCell>Completed</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{rowData}</Table.Body>
          </Table>
        </div>
      );
    };
    var sortFn = (a, b) => {
      if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) return -1;
      if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) return 1;
      return 0;
    };
    if (this.state.userProfiles.length) {
      var profileRows = this.state.userProfiles.sort(sortFn).map(profileItem => {
        return (
          <Table.Row key={profileItem.displayName}>
            <Table.Cell>{profileItem.displayName}</Table.Cell>
            <Table.Cell>{profileItem.userId}</Table.Cell>
            <Table.Cell>{profileItem.firstName}</Table.Cell>
            <Table.Cell>{profileItem.lastName}</Table.Cell>
            <Table.Cell>{profileItem.phoneNumber}</Table.Cell>
            <Table.Cell>{profileItem.zipCode}</Table.Cell>
            <Table.Cell>{profileItem.points ? `${((profileItem.points/(this.state.allActivities.totalRoutines+this.state.allActivities.totalRewires))*100).toFixed(2)}%` : profileItem.points}</Table.Cell>
          </Table.Row>
        );
      });
      return tableMarkup(`Session Users (${this.state.userProfiles.length})`, profileRows);
    } else {
      return <h3 className="center-text extrapaddingtop">There are currently no session users to display</h3>;
    }
  };

  statsGetUserDataButton = () => {
    return <Button primary size={"massive"} onClick={this.statsGetUserData}>Generate Stats Tables</Button>;
  };

  currentTab = () => {
    if (!this.props.authorizedUser) {
      return <SessionVisitorLogin />;
    }
    switch (this.props.subMenuName) {
      case "overview": {
        return this.sectionOverview();
      }
      case "habits": {
        return this.sectionHabits();
      }
      case "teams": {
        return this.sectionTeams();
      }
      case "rankings": {
        return this.sectionRankings();
      }
      case "stats": {
        return this.sectionStats();
      }
      default: {
        return null;
      }
    }
  };

  handleTeamVisibilityChange = isVisible => {
    //console.log("CHANGE: " + isVisible);
    if (isVisible === true) {
      /* this.setState({ isLoading: true, error: null }); */
      /* this.refreshTeamData(); */
      this.refreshUserHabits(true);
    } else {
      this.setState({ showTeamMembersTable: false });
    }
  };

  handleHabitsVisibilityChange = isVisible => {
    //console.log("CHANGE: " + isVisible);
    if (isVisible === true) {
      /* this.setState({ isLoading: true, error: null }); */
      this.refreshUserHabits(true);
    }
  };

  handleRankingsVisibilityChange = isVisible => {
    //console.log("CHANGE: " + isVisible);
    if (isVisible === true) {
      /* this.setState({ error: null }); */
      /* this.refreshRankings(); */
      this.refreshUserHabits(true);
    }
  };

  render() {
    const { sessionID } = this.props.match.params;
    if (this.state.sessionNotFound) {
      return <SessionNotFound visible={this.pageLoading()} />;
    } else {
      return (
        <div>
          {this.props.appLoading ? null : (
            <SecondaryMenu
              subMenuName={this.props.subMenuName}
              sessionID={sessionID}
              visible={this.isEnrolled()}
              disabled={this.state.isLoading}
              pendingTeamRequests={this.isOwnerOfTeam() ? this.isOwnerOfTeam().pendingMembers.length : null}
            />
          )}
          {this.props.appLoading ? null : this.currentTab()}
        </div>
      );
    }
  }
}

export default SessionPage;
