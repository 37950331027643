export default {
  firebase: {
    apiKey: "AIzaSyCsGzPqEuSC5GEsm33WYswsPwLUZz2Myq4",
    authDomain: "routine-rewire.firebaseapp.com",
    databaseURL: "https://routine-rewire.firebaseio.com",
    projectId: "routine-rewire",
    storageBucket: "routine-rewire.appspot.com",
    messagingSenderId: "112212410510"
  },
  stripe: {
    publishableKey: "pk_live_WfxuEHf6gQqmFONS6TMmb4EW",
    testingKey: "pk_test_bUwZGflKNd4csr7WjMDmsetn"
  },
  app: {
    siteTitle: "Routine Rewire",
    url: "https://app.routinerewire.com/",
    functionsUrl: "https://us-central1-routine-rewire.cloudfunctions.net"
  }
};
