import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ContentContainer from '../../Layout/ContentContainer/ContentContainer.js';
import { Button, Form, Grid } from 'semantic-ui-react';
import {db, signinUser} from '../../../firebase/index.js';
import appConfig from '../../../appConfig.js';
import {WOW} from 'wowjs/dist/wow.js';

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  isLoading: false,
  redirectToReferrer: false
}

class AccountSignin extends Component {

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    document.title = `${appConfig.app.siteTitle} - Account Sign-in`;
  }

  componentDidMount = () => {
    new WOW({live: false}).init();
  }

  validateForm() {
    return ((this.state.email.length > 0) && (this.state.password.length > 0));
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    let loginDestination = "/dashboard";
    //if we hit a protected route and the user is not logged in, redirect them upon login
    if(this.props.location.state) {
      loginDestination = this.props.location.state.from;
    }
    const { history } = this.props;
    this.setState({ isLoading: true });
    signinUser(this.state.email.trim(), this.state.password.trim()).then(authUser => {
      
      if (authUser.user.emailVerified) {
        var docRef = db.collection("userProfiles").doc(authUser.user.uid);
        docRef
          .get()
          .then(doc => {
            this.setState(() => ({ ...INITIAL_STATE }));
            if (doc.exists) {
              this.setState({ ...INITIAL_STATE });
              history.push(loginDestination);
            } else {
              this.setState({ ...INITIAL_STATE });
              history.push('/account/profile');
            }
          })
          .catch(error => {
            this.setState({ isLoading: false, error: error });
          });
      } else {
        this.setState(() => ({ ...INITIAL_STATE }));
        history.push("/account/action?result=pendingVerificationDoubleCheck");
      }
    }).catch(haserror => {
      this.setState({ isLoading: false });
      this.setState({ error: haserror });
    });
  }

  render() {
    const style = { textAlign: 'center' };
    const errorStyle = { fontWeight: 'bold', color: '#a51a28', textAlign: 'center' }
    return (
      <ContentContainer>
        <div className="AccountSignin">
          <Grid container>
          {this.props.location.state ? <Grid.Row centered><Grid.Column mobile={16} tablet={16} computer={16}><p style={errorStyle}>This action requires you to be signed-in.</p></Grid.Column></Grid.Row> : null}
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={10} computer={8}>
                <h2 className="section-title wow flipInX" style={style} data-wow-duration="0.35s" data-wow-delay="0s" data-wow-offset="0">Account Sign-in</h2>
                <div className="wow fadeIn" data-wow-duration="0.35s" data-wow-delay="0s" data-wow-offset="0" data-wow-iteration="1">
                <p style={style}>Don't have an account?  <Link className="hyperlink" to={"/account/register"}>Create one here</Link>.</p>
                {this.state.error && <p style={errorStyle}>{this.state.error.message}</p>}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={10} computer={6}>
                <Form autoComplete="off" onSubmit={this.handleSubmit} loading={this.state.isLoading} size={"large"} className="wow fadeIn" data-wow-duration="0.35s" data-wow-delay="0s" data-wow-offset="0" data-wow-iteration="1">
                  <Form.Field>
                    <label>E-mail</label>
                    <input placeholder='E-mail' type='text' name='email' disabled={this.state.isLoading} value={this.state.email} onChange={this.handleChange} />
                  </Form.Field>
                  <Form.Field>
                    <label>Password</label>
                    <input placeholder='Password' type='password' name='password' disabled={this.state.isLoading} value={this.state.password} onChange={this.handleChange} />
                    <Link className="hyperlink underfield" to={"/account/forgotpassword"}>Forgot your password?</Link>
                  </Form.Field>
                  <Button primary type='submit' size={"medium"} floated={"right"} disabled={!this.validateForm() || this.state.isLoading}>Sign-in</Button>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </ContentContainer>
    );
  }
}

export default AccountSignin;
