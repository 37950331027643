import React, { PureComponent } from "react";
import { Grid, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ContentContainer from "../Layout/ContentContainer/ContentContainer.js";

class SessionNotFound extends PureComponent {
  render() {
    const style = { textAlign: "center" };
    if (this.props.isLoading) {
      return null;
    } else {
      return (
        <ContentContainer>
          <div className="error-404">
            <Grid container>
              <Grid.Row centered columns={1}>
                <Grid.Column>
                  <h2 className="section-title" style={style}>
                    Oops! This is awkward.
                  </h2>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns={2}>
                <Grid.Column>
                  <p style={style}>
                    That rewire session doesn't seem to exist. <br />
                    Please double-check your links and try again.
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns={2}>
                <Grid.Column>
                  <div style={style}>
                    <Button basic color="red" size={"large"} as={Link} to="/">
                      Return Home
                    </Button>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </ContentContainer>
      );
    }
  }
}

export default SessionNotFound;
